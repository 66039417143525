import React from "react";

const IconSearch = ({ color = "#e31c19" }) => {
  return (
    <svg
      id="UI_icon_search_filled"
      data-name="UI icon/search/filled"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Union"
        d="M18,19a.994.994,0,0,1-.707-.293l-2.675-2.676A8.9,8.9,0,0,1,9,18a9,9,0,1,1,9-9,8.9,8.9,0,0,1-1.968,5.618l2.676,2.675A1,1,0,0,1,18,19ZM9,2a7,7,0,1,0,7,7A7.008,7.008,0,0,0,9,2Z"
        transform="translate(2 2)"
        fill={color}
      />
    </svg>
  );
};

export default IconSearch;
