import React, { useState } from "react";
import { IconArrowDown1, IconArrowUp1 } from "../../../icons/Icon";

const CellTh = ({ children, onClick, color, noOrder }) => {
  const [order, setOrder] = useState("desc");

  const handleClick = () => {
    if (!onClick || !!noOrder) return;

    let _order = order === "desc" ? "asc" : "desc";
    setOrder(_order);
    onClick(_order);
  };

  return (
    <div
      className="d-flex align-items-center"
      style={{ cursor: "pointer", userSelect: "none", whiteSpace: "nowrap" }}
      onClick={() => handleClick()}
    >
      {children}
      <span className={`ms-2 ${!!noOrder && "d-none"}`}>
        {order === "asc" ? (
          <IconArrowDown1 color={color} />
        ) : (
          <IconArrowUp1 color={color} />
        )}
      </span>
    </div>
  );
};

export default CellTh;
