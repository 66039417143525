import React from "react";

const IconUser = ({ color = "#e31c19" }) => {
  return (
    <svg
      id="UI_icon_person_light"
      data-name="UI icon/person/light"
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16.333"
      viewBox="0 0 15 16.333"
    >
      <path
        id="Ellipse_132_Stroke_"
        data-name="Ellipse 132 (Stroke)"
        d="M4,8A4,4,0,1,1,8,4,4,4,0,0,1,4,8ZM4,1.6A2.4,2.4,0,1,0,6.4,4,2.4,2.4,0,0,0,4,1.6Z"
        transform="translate(3.5)"
        fill={color}
      />
      <path
        id="Vector_631_Stroke_"
        data-name="Vector 631 (Stroke)"
        d="M5,1.667A3.333,3.333,0,0,0,1.667,5v.833A.833.833,0,1,1,0,5.833V5A5,5,0,0,1,5,0h5a5,5,0,0,1,5,5v.833a.833.833,0,1,1-1.667,0V5A3.333,3.333,0,0,0,10,1.667Z"
        transform="translate(0 9.667)"
        fill={color}
      />
    </svg>
  );
};

export default IconUser;
