import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { optionsBar } from "./graph-config";
import { Card } from "react-bootstrap";
import SmuLoading from "../smu-loading/SmuLoading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarGraph = ({ labels, data, loading, title }) => {
  const _data = {
    labels,
    datasets: data.map((d) => ({
      ...d,
      barThickness: 40,
      stack: 0,
    })),
  };

  return (
    <Card className="smu-card">
      <Card.Header className="border-0 bg-transparent">
        <div className="d-flex">
          <div className="smu-badge smu-bg-salmon" />
          <div className="fs-5 ms-3 smu-text-dark">{title}</div>
        </div>
      </Card.Header>
      <Card.Body>
        {!loading ? (
          <Bar options={optionsBar} data={_data} />
        ) : (
          <div className="d-flex mt-5">
            <SmuLoading />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default BarGraph;
