import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Select from "../select/Select";
import _ from "lodash";
import { IconShoppingBag } from "../../icons/Icon";
import SmuTable from "../../util/smu-table/SmuTable";

const LocalSaleTable = ({
  locals,
  manager,
  showButtonGraph,
  onGraph,
  managers,
  filter,
  loading,
}) => {
  const columns = [
    {
      name: "Local",
      selector: (row) => row.LOCAL,
      sortable: true,
      cell: (row) => <div className="cell">{row.LOCAL}</div>,
    },
    {
      name: "Venta MM",
      selector: (row) => parseFloat(row.VENTA),
      sortable: true,
      cell: (row) => <div className="cell">{parseFloat(row.VENTA)}</div>,
    },
    {
      name: "SSS %",
      selector: (row) => parseFloat(row.SSS),
      sortable: true,
      cell: (row) => <div className="cell">{parseFloat(row.SSS)}</div>,
    },
    {
      name: "Ppto MM",
      selector: (row) => parseFloat(row.DESV),
      sortable: true,
      cell: (row) => <div className="cell">{parseFloat(row.DESV)}</div>,
    },
  ];

  return (
    <Card className="smu-card">
      <Card.Header className="border-0 bg-transparent">
        <Row>
          <Col col={6}>
            <div className="d-flex">
              <div className="smu-badge smu-bg-orange" />
              <div className="fs-5 ms-3 smu-text-dark">
                {manager?.GTE_MERC ? manager?.GTE_MERC + " | " : ""} Tiendas
              </div>
            </div>
          </Col>
          {showButtonGraph && (
            <Col col={4} className="ms-auto d-flex d-lg-none">
              <button
                type="button"
                className="ms-auto px-3 btn-see-graph"
                onClick={() => onGraph()}
              >
                <IconShoppingBag color="#E31C19" />
                <span className="ms-2">Ver Gráfico</span>
              </button>
            </Col>
          )}
          <Col col={12} lg={4} className="mt-4 mt-lg-0 d-block d-lg-none">
            <Select
              value={manager?.GTE_MERC}
              options={managers.map((m) => ({
                id: m.GTE_MERC,
                title: m.GTE_MERC,
              }))}
              onChange={({ target }) => filter(target.value)}
            />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-relative">
        <SmuTable
          columns={columns}
          data={locals}
          className="striped"
          loading={loading}
        />
      </Card.Body>
    </Card>
  );
};

export default LocalSaleTable;
