import React from "react";

const IconTrash = ({ color = "#e31c19" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.95"
      height="15.95"
      viewBox="0 0 15.95 15.95"
    >
      <g
        id="UI_icon_trash_light"
        data-name="UI icon/trash/light"
        transform="translate(-2 -2)"
      >
        <path
          id="Path_38_Copy_Stroke_"
          data-name="Path 38 Copy (Stroke)"
          d="M.8,0a.8.8,0,0,1,.8.8V4.785a.8.8,0,0,1-1.6,0V.8A.8.8,0,0,1,.8,0Z"
          transform="translate(7.583 8.38)"
          fill={color}
        />
        <path
          id="Path_38_Copy_Stroke_2"
          data-name="Path 38 Copy (Stroke)"
          d="M.8,0a.8.8,0,0,1,.8.8V4.785a.8.8,0,0,1-1.6,0V.8A.8.8,0,0,1,.8,0Z"
          transform="translate(10.773 8.38)"
          fill={color}
        />
        <path
          id="Union"
          d="M11.963,15.95H3.988a2.4,2.4,0,0,1-2.393-2.392V3.988H.8a.8.8,0,0,1,0-1.6h3.19A2.4,2.4,0,0,1,6.38,0H9.57a2.4,2.4,0,0,1,2.393,2.392h3.19a.8.8,0,0,1,0,1.6h-.8v9.57A2.4,2.4,0,0,1,11.963,15.95ZM3.19,3.988v9.57a.8.8,0,0,0,.8.8h7.975a.8.8,0,0,0,.8-.8V3.988H3.19ZM6.38,1.595a.8.8,0,0,0-.8.8h4.785a.8.8,0,0,0-.8-.8Z"
          transform="translate(2 2)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default IconTrash;
