import React from "react";

const IconBar = ({ color = "#e31c19" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.333"
      height="12.667"
      viewBox="0 0 19.333 12.667"
    >
      <g
        id="Grupo_19967"
        data-name="Grupo 19967"
        transform="translate(-8188.333 6552.334)"
      >
        <path
          id="Trazado_196"
          data-name="Trazado 196"
          d="M23.083,13.25H5.75a1,1,0,0,1,0-2H23.083a1,1,0,0,1,0,2"
          transform="translate(8183.583 -6558.25)"
          fill={color}
        />
        <path
          id="Trazado_197"
          data-name="Trazado 197"
          d="M23.083,17.25H5.75a1,1,0,0,1,0-2H23.083a1,1,0,0,1,0,2"
          transform="translate(8183.583 -6556.917)"
          fill={color}
        />
        <path
          id="Trazado_198"
          data-name="Trazado 198"
          d="M23.083,9.25H5.75a1,1,0,0,1,0-2H23.083a1,1,0,0,1,0,2"
          transform="translate(8183.583 -6559.583)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default IconBar;
