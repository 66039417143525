import React, { useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IconArrowsRightLeft } from "../../icons/Icon";
import activityIcon from "../../assets/images/activity-icon.png";
import { pascalCase, numberFormatter, priceFormatter } from "../../helpers";
import SmuTable from "../../util/smu-table/SmuTable";

const DailyIndicatorsSummaryTable = ({ onSwith, sales, loading }) => {
  const columns = useMemo(
    () => [
      {
        name: "Línea",
        selector: (row) => row.descLinea,
        cell: (row) => <div className="cell">{pascalCase(row.descLinea)}</div>,
      },
      {
        name: "Material",
        selector: (row) => row.descMat,
        cell: (row) => <div className="cell">{pascalCase(row.descMat)}</div>,
      },
      {
        name: "Un. Vendidas",
        selector: (row) => row.unidadesVendidasDiario,
        cell: (row) => (
          <div className="cell">
            {numberFormatter.format(row.unidadesVendidasDiario)}
          </div>
        ),
      },
      {
        name: "Tot. Ventas",
        selector: (row) => row.ventaTotalDiario,
        cell: (row) => (
          <div
            className="cell"
            style={{
              color: row.ventaTotalDiario >= 0 ? "#91C67F" : "e31c19",
            }}
          >
            {priceFormatter.format(row.ventaTotalDiario)}
          </div>
        ),
      },
      {
        name: "Cant. Ticket",
        selector: (row) => row.cantidadDeTicketDiario,
        cell: (row) => (
          <div className="cell">
            {numberFormatter.format(row.cantidadDeTicketDiario)}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Card className="smu-card">
      <Card.Header className="border-0 bg-transparent">
        <Row>
          <Col col={12} xs={10} lg={5} className="mb-4 mb-lg-0">
            <div className="d-flex align-items-center">
              <img src={activityIcon} alt="ICON" />
              <div className="fs-5 ms-3 smu-text-dark">Indicadores Diarios</div>
            </div>
          </Col>
          <Col col={12} xs={2} className="d-lg-none">
            <div className="d-flex">
              <button
                type="button"
                className="btn-swith ms-auto"
                style={{ backgroundColor: "#8C96F6" }}
                onClick={() => onSwith((val) => !val)}
              >
                <IconArrowsRightLeft color="#fff" />
              </button>
            </div>
          </Col>
          <Col col={12} lg={5}>
            <div className="d-flex">
              <div className="fs-5 me-auto ms-lg-auto me-lg-0 smu-text-dark">
                | Resumen por PLU
              </div>
            </div>
          </Col>
          <Col col={12} lg={2} className="d-none d-lg-block">
            <div className="d-flex">
              <button
                type="button"
                className="btn-swith ms-auto"
                style={{ backgroundColor: "#8C96F6" }}
                onClick={() => onSwith((val) => !val)}
              >
                <IconArrowsRightLeft color="#fff" />
              </button>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <SmuTable
          columns={columns}
          data={sales}
          className="red-table"
          loading={loading}
        />
      </Card.Body>
    </Card>
  );
};

export default DailyIndicatorsSummaryTable;
