import React from "react";

const IconHome = ({ color = "#e31c19" }) => {
  return (
    <svg
      id="Nav_item"
      data-name="Nav item"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19.964"
      viewBox="0 0 20 19.964"
    >
      <g id="Left">
        <g id="UI_icon_home_light" data-name="UI icon/home/light">
          <path
            id="Union"
            d="M17,19.964H3a3,3,0,0,1-3-3V9.3A3,3,0,0,1,.993,7.07l7-6.3a3,3,0,0,1,4.014,0l7,6.3A3,3,0,0,1,20,9.3v7.664A3,3,0,0,1,17,19.964Zm-8-10h2a3,3,0,0,1,3,3v5h3a1,1,0,0,0,1-1V9.3a1,1,0,0,0-.331-.743l-7-6.3a1,1,0,0,0-1.337,0l-7,6.3A1,1,0,0,0,2,9.3v7.664a1,1,0,0,0,1,1H6v-5A3,3,0,0,1,9,9.964Zm0,2a1,1,0,0,0-1,1v5h4v-5a1,1,0,0,0-1-1Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default IconHome;
