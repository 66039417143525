import { IconHome, IconSafe, IconStar, IconUsers } from "./icons/Icon";

const routes = [
  {
    id: "venta_online",
    title: "Venta Online",
    path: "/portal-sales/sales",
    Icon: (props) => <IconHome {...props} />,
  },
  {
    id: "metricas_operactionales",
    title: "Metricas Operacionales",
    path: "/portal-sales/operational-metrics",
    Icon: (props) => <IconStar {...props} />,
  },
  {
    id: "monitor_promocional",
    title: "Monitor Promocional",
    path: "/portal-sales/promotional-monitor",
    Icon: (props) => <IconSafe {...props} />,
  },
];

const routesAdmin = [
  {
    id: "users",
    title: "Administrar Usuarios",
    path: "/portal-sales-admin/users",
    Icon: (props) => <IconUsers {...props} />,
  },
];

export { routes, routesAdmin };
