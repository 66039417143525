import React from "react";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";

const color = "#32373E";
const activeColor = "#E31C19";

const NavItem = ({ route }) => {
  let resolved = useResolvedPath(route.path);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavLink to={route.path} className="menu-item">
      <div className="icon">
        <route.Icon color={match ? activeColor : color} />
      </div>
      <span className="text ps-3">
        {route.title}
      </span>
    </NavLink>
  );
};

export default NavItem;
