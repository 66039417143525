import React, { useEffect, useState } from "react";
import SmuUserIcon from "../../util/smu-user-icon/SmuUserIcon";
import SmuModal from "../../util/smu-modal/SmuModal";
import { Form, Row } from "react-bootstrap";
import SmuProfilePhotoEditor from "../smu-profile-photo-editor/SmuProfilePhotoEditor";
import loadingGif from "../../assets/images/loading.gif";

const profiles = [
  {
    id: 1,
    title: "Admin",
  },
  {
    id: 2,
    title: "Usuario",
  },
];

const userTypes = [
  {
    id: "LDAP",
    title: "LDAP",
  },
  {
    id: "Local",
    title: "Local",
  },
];
const editorStyles = { height: 120, width: 120 };

const EditUser = ({ user, onHide, editUser }) => {
  const iconStyle = { height: 48, width: 48, borderRadius: 12 };

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("LDAP");
  const [profileId, setProfileId] = useState(2);
  const [permission, setPermission] = useState({ venta_online: true, metricas_operactionales: false, monitor_promocional: false });
  const [file, setFile] = useState("");

  useEffect(() => {
    if (!user) {
      setName("");
      setPassword("");
      setProfileId(2);
      setUserType("LDAP");
      return;
    }

    setName(user?.name);
    setPassword("");
    setUserType(user?.user_type || "LDAP");
    setProfileId((user.admin == true || user.admin == 'true') ? 1 : 2);
    setPermission(user.permission);
  }, [user]);


  const handleEdit = (event) => {
    event.preventDefault();
    if (!profileId) return;

    setLoading(true);
    const newUser = {
      ...user,
      name,
      password,
      userType,
      admin: profileId == 1,
      avatar: "",
      permission,
    };

    editUser(newUser, file).finally(() => {
      setLoading(false);
      setName("");
      setPassword("");
      setProfileId("");
      setFile("");
      setUserType("LDAP");
      onHide();
    });
  };

  return (
    <SmuModal
      show={Boolean(user)}
      onHide={onHide}
      size="lg"
      className="smu-modal px-2 px-lg-0"
      contentTitle={
        <div className="d-flex align-items-center">
          <SmuUserIcon color="#E31C19" styles={iconStyle} />
          <div className="ms-3 fs-5 text-truncate">Perfil usuario</div>
        </div>
      }
    >
      <div className="my-5 d-flex">
        <SmuProfilePhotoEditor
          styles={editorStyles}
          avatar={user?.avatar}
          file={file}
          setFile={setFile}
        />
      </div>

      <div className="fs-6 mb-3">Dato del perfil</div>

      <Form
        className="smu-form pb-5"
        id="edit-user"
        onSubmit={(event) => handleEdit(event)}
      >
        <Row>
          <Form.Group className="col-6">
            <Form.Label htmlFor="username">Usuario</Form.Label>
            <Form.Control
              type="text"
              id="username"
              className="text-dark"
              value={name}
              onChange={({ target }) => setName(target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label htmlFor="profile">Perfil</Form.Label>
            <Form.Select
              type="text"
              id="profile"
              className="text-dark"
              value={profileId}
              onChange={e => setProfileId(Number(e.target.value))}
            >
              {profiles.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>

        <Row className="mt-4">
          <Form.Group className="col-6">
            <Form.Label htmlFor="passwd">Contraseña</Form.Label>
            <Form.Control
              type="password"
              id="passwd"
              disabled={userType==="LDAP"}
              className="text-dark"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
          </Form.Group>
          <Form.Group className="col-6">
          <Form.Label htmlFor="userType">Tipo de Usuario</Form.Label>
            <Form.Select
              type="text"
              id="userType"
              className="text-dark"
              value={userType}
              onChange={e => setUserType(e.target.value)}
            >
              {userTypes.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mt-4">
          <div className="fs-6 mb-3">Dato del perfil</div>
          <div>
            <Form.Check
              className="mb-2"
              type="switch"
              label="Venta online"
              value={permission.venta_online}
              checked={permission.venta_online}
              onChange={(event) =>
                setPermission((prev) => ({
                  ...prev,
                  venta_online: event.target.checked ? true : false,
                }))
              }
            />
            <Form.Check
              className="mb-2"
              type="switch"
              label="Metricas operacionales"
              value={permission.metricas_operactionales}
              checked={permission.metricas_operactionales}
              onChange={(event) =>
                setPermission((prev) => ({
                  ...prev,
                  metricas_operactionales: event.target.checked ? true : false,
                }))
              }
            />
            <Form.Check
              className="mb-2"
              type="switch"
              label="Monitor promocional"
              value={permission.monitor_promocional}
              checked={permission.monitor_promocional}
              onChange={(event) =>
                setPermission((prev) => ({
                  ...prev,
                  monitor_promocional: event.target.checked ? true : false,
                }))
              }
            />
          </div>
        </Row>
      </Form>

      <div className="d-flex mb-3 mt-5">
        {!loading ? (
          <button
            form="edit-user"
            type="submit"
            className="ms-auto smu-generic-button solid-btn py-1"
          >
            Guardar
          </button>
        ) : (
          <div className="ms-auto smu-generic-button solid-btn py-1 px-5">
            <img
              src={loadingGif}
              alt="Cargando"
              className="m-auto"
              width={20}
              height={20}
            />
          </div>
        )}
      </div>
    </SmuModal>
  );
};

export default EditUser;
