import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { SMU_TOKEN } from "../constants";

const RequireAuth = ({ children }) => {
  const token = localStorage.getItem(SMU_TOKEN);

  const location = useLocation();

  return !token ? (
    <Navigate to="/access" state={{ from: location }} replace />
  ) : (
    children
  );
};

export default RequireAuth;
