import React from "react";

const IconSignOut = ({ color = "#e31c19" }) => {
  return (
    <svg
      id="UI_icon_logout_light"
      data-name="UI icon/logout/light"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Union"
        d="M9.707.293A1,1,0,1,0,8.293,1.707L10.086,3.5H1a1,1,0,0,0,0,2h9.086L8.293,7.293A1,1,0,0,0,9.707,8.707L12.5,5.914a2,2,0,0,0,0-2.828Z"
        transform="translate(8 7.5)"
        fill={color}
      />
      <path
        id="Vector_587_Stroke_"
        data-name="Vector 587 (Stroke)"
        d="M0,3A3,3,0,0,1,3,0H9a3,3,0,0,1,3,3V4a1,1,0,0,1-2,0V3A1,1,0,0,0,9,2H3A1,1,0,0,0,2,3V17a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V16a1,1,0,0,1,2,0v1a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3Z"
        transform="translate(3 2)"
        fill={color}
      />
    </svg>
  );
};

export default IconSignOut;
