import React from "react";
import { Col, Row } from "react-bootstrap";
import UsersTable from "../../../components/users-table/UsersTable";

const Users = () => {


  return (
    <Row>
      <Col col={12} className="col-12">
        <UsersTable  />
      </Col>
    </Row>
  );
};

export default Users;
