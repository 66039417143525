const prepareText = (text) => {
  return (!!text) ? 
    text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase() : '';
}
  
const pascalCase = (string) =>
  string.replace(
    /(\w)(\w*)/g,
    (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
  );

const priceFormatter = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",
});

const outMM = (val) => val.replace(/MM/g, "");

var numberFormatter = new Intl.NumberFormat("es-CL", {
  maximumFractionDigits: 0,
});

export { prepareText, pascalCase, priceFormatter, numberFormatter, outMM };
