import interceptors from "./interceptors/index";

const getUsers = () => {
  return interceptors({
    url: "/auth/users",
    method: "GET",
  });
};

const signUp = (data) => {
  return interceptors({
    url: "/auth/signup",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const deleteUser = (userame) => {
  return interceptors({
    url: "/auth/users/" + userame,
    method: "DELETE",
  });
};

export { getUsers, signUp, deleteUser };
