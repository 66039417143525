import React from "react";

const SmuBadge = ({ _className, color }) => {
  return (
    <span
      className={`smu-badge ${_className || ""}`}
      style={{ backgroundColor: color }}
    />
  );
};

export default SmuBadge;
