import React from "react";
import "../../assets/styles/smu-loading.scss";
import loading from "../../assets/images/loading.gif";

const SmuLoading = () => {
  return <div className="smu-loading">
    <img src={loading} alt="Cargando" />
    <span className="ms-3">Cargando...</span>
  </div>;
};

export default SmuLoading;
