import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { optionsLine } from "./graph-config";
import { Card, Col, Row } from "react-bootstrap";
import { IconArrowsUpDown, IconShoppingBag } from "../../icons/Icon";
import SmuLoading from "../smu-loading/SmuLoading";

ChartJS.register(
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineController,
  LineElement,
  PointElement
);

const LineGraph = ({ labels, data, title, loading }) => {
  const _data = {
    labels,
    datasets: data.map((item) => ({
      fill: false,
      lineTension: 0.3,
      backgroundColor: item.color,
      borderColor: item.color,
      borderCapStyle: "round",
      borderJoinStyle: "round",
      pointBorderColor: item.color,
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: item.color,
      pointHoverBorderColor: item.color,
      pointHoverBorderWidth: 5,
      pointRadius: 0,
      pointHitRadius: 5,
      data: item.data,
      label: item.label,
    })),
  };

  return (
    <Card className="smu-card">
      <Card.Header className="border-0 bg-transparent">
        <Row>
          <Col col={12}>
            <div className="d-flex">
              <div className="smu-badge smu-bg-green-light" />
              <div className="fs-5 ms-3 smu-text-dark me-auto">{title}</div>
            </div>
          </Col>
          <Col col={12} lg={7} className="mt-4 mt-lg-0 d-none">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn-order-sss px-3 ms-auto ms-lg-3 me-3"
              >
                <IconArrowsUpDown color="#fff" />
                <span className="ms-2">SSS</span>
              </button>
              <button type="button" className="btn-budget px-3 mx-3">
                <IconShoppingBag color="#E31C19" />
                <span className="ms-2">Presupuesto</span>
              </button>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        {!loading ? (
          <Line options={optionsLine} data={_data} />
        ) : (
          /* <LineGraph labels={labels} dataLine={data} /> */
          <div className="d-flex mt-5">
            <SmuLoading />
          </div>
        )}
      </Card.Body>
    </Card>
  );

};

export default LineGraph;
