import interceptors from "./interceptors/index";

const fetchPromotionsPlu = (date) => {
  return interceptors({
    url: "/online-apoteosico/promotion-plu",
    method: "GET",
    params: { date },
  });
};

const fetchPromotionSummary = (date) => {
  return interceptors({
    url: "/online-apoteosico/promotion-resume",
    method: "GET",
    params: { date },
  });
};

const fetchPromotionsSale = (date) => {
  return interceptors({
    url: "/online-apoteosico/promotion-sale",
    method: "GET",
    params: { date },
  });
};

export { fetchPromotionSummary, fetchPromotionsPlu, fetchPromotionsSale };
