import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IconShoppingBag } from "../../icons/Icon";
import { Search } from "../search/Search";
import SmuTable from "../../util/smu-table/SmuTable";
import { prepareText } from "../../helpers";
import _ from "lodash";

const ManagerSaleTable = ({
  onGraph,
  managers,
  manager,
  showButtonGraph,
  filter,
  loading,
}) => {
 
  const handleFilter = (managerName) => {
    const _manager = managers.find((m) => m.GTE_MERC === managerName);
    if (!_manager) return;
    filter(_manager);
  };

  const columns = [
    {
      name: "Gte Zonal",
      selector: (row) => row.GTE_MERC,
      sortable: true,
      cell: (row) => (
        <div className="cell">
          <label className="form-check d-flex align-items-center m-0">
            <input
              className="form-check-input me-3"
              type="checkbox"
              id={"filter" + row.GTE_MERC}
              onChange={() => handleFilter(row.GTE_MERC)}
              checked={row.GTE_MERC === manager?.GTE_MERC}
            />
            <span>{row.GTE_MERC}</span>
          </label>
        </div>
      ),
    },
    {
      name: "Venta MM",
      selector: (row) => parseFloat(row.VENTA),
      sortable: true,
      cell: (row) => <div className="cell">{parseFloat(row.VENTA)}</div>,
    },
    {
      name: "SSS %",
      selector: (row) => parseFloat(row.SSS),
      sortable: true,
      cell: (row) => <div className="cell">{parseFloat(row.SSS)}</div>,
    },
    {
      name: "Ppto MM",
      selector: (row) => parseFloat(row.DESV),
      sortable: true,
      cell: (row) => <div className="cell">{parseFloat(row.DESV)}</div>,
    },
    {
      name: "SSS A %",
      selector: (row) => parseFloat(row.SSS_ACUM),
      sortable: true,
      cell: (row) => <div className="cell">{parseFloat(row.SSS_ACUM)}</div>,
    },
  ];

  const [search, setSearch] = useState("");


  const data = managers.filter((m) => {
    const _name = prepareText(m.GTE_MERC);
    const _search = prepareText(search);
    return !search || _name.includes(_search);
  });

  return (
    <Card className="smu-card">
      <Card.Header className="border-0 bg-transparent">
        <Row>
          <Col col={6}>
            <div className="d-flex">
              <div className="smu-badge smu-bg-orange" />
              <div className="fs-5 ms-3 smu-text-dark">Gerentes zonales</div>
            </div>
          </Col>
          {showButtonGraph && (
            <Col col={4} className="ms-auto d-flex d-lg-none">
              <button
                type="button"
                className="ms-auto px-3 btn-see-graph"
                onClick={() => onGraph()}
              >
                <IconShoppingBag color="#E31C19" />
                <span className="ms-2">Ver Gráfico</span>
              </button>
            </Col>
          )}
          <Col col={12} lg={4} className="mt-4 mt-lg-0">
            <Search
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <SmuTable
          className="striped"
          columns={columns}
          data={data}
          loading={loading}
        />
      </Card.Body>
    </Card>
  );
};

export default ManagerSaleTable;
