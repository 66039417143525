import React from "react";
import { IconX } from "../../icons/Icon";
import "../../assets/styles/btnClose.scss";

const BtnClose = ({ control }) => {
  return (
    <button
      type="button"
      className="smu-btn-close shadow"
      onClick={() => control()}
    >
      <IconX />
    </button>
  );
};

export default BtnClose;
