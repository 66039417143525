import React, { useRef, useState, useContext } from "react";
import "../../../assets/styles/topbar.scss";
import { Navbar, Popover } from "react-bootstrap";
import Overlay from "react-bootstrap/Overlay";
import defaultUser from "../../../assets/images/user_default.png";
import TotalSalesSummary from "../parts/TotalSalesSummary";
import {
  IconBar,
  IconCalendar,
  IconConfig,
  IconReload,
} from "../../../icons/Icon";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import SmuProfile from "../../../util/smu-profile/SmuProfile";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../../store/StoreContext";
import SmuLoading from "../../../util/smu-loading/SmuLoading";
import useStart from "../../../hooks/useStart";
import { logDOM } from "@testing-library/react";

const color = "#E31C19";

const Topbar = ({ sidebarControl }) => {
  const popoverRef = useRef(null);
  const navigate = useNavigate();
  const { setShowPreload, date, setDate, loading, loggedUser, isAdmin } =
    useContext(StoreContext);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [target, setTarget] = useState(null);
  const handleAction = () => {
    setShowPreload(true);
    const timeout = setTimeout(() => {
      navigate("/portal-sales-admin/users");
      setShowPreload(false);
      clearTimeout(timeout);
    }, 1500);
  };

  const { start } = useStart();

  const profileOptions = [
    {
      id: 1,
      title: "Ir al admin de usuario",
      Icon: <IconConfig color="#33383F" />,
      action: handleAction,
    },
  ];

  const handleOpenCalendar = (event) => {
    setOpenCalendar((val) => !val);
    setTarget(event.target);
  };

  const handleChangue = (value) => {
    if (loading) return;

    setDate(value);
    const timeout = setTimeout(() => {
      setOpenCalendar(false);
      clearTimeout(timeout);
    }, 750);
  };

  const reloadData = () => {
    let _date = new Date();
    setDate(_date);
    start(_date);
  };

  return (
    <>
      <Navbar bg="white" expand="lg" className="p-3" ref={popoverRef}>
        <div className="d-flex mx-lg-5 w-100">
          <TotalSalesSummary className="d-none d-lg-flex" />

          <button
            className="btn-toggle d-flex d-lg-none"
            onClick={() => sidebarControl(true)}
          >
            <IconBar color={color} />
          </button>

          <div className="divider" />

          <div className="d-flex px-lg-3 mx-auto mx-lg-0">
            <div className="smu-calendar">
              <button
                type="button"
                className="btn-calendar my-auto fs-6 px-3"
                onClick={(event) => handleOpenCalendar(event)}
              >
                <IconCalendar color="#E31C19" />
                <span className="text-truncate ms-2 d-none d-lg-flex">
                  Ver Calendario
                </span>
              </button>

              <Overlay
                show={openCalendar}
                target={target}
                placement="bottom"
                container={popoverRef}
                containerPadding={20}
                trigger="focus"
              >
                <Popover className="smu-calendar__popover shadow border-0">
                  <div className="body">
                    {loading && (
                      <div className="calendar-placeholder">
                        <SmuLoading />
                      </div>
                    )}
                    <Calendar
                      value={date}
                      onChange={(value) => handleChangue(value)}
                    />
                  </div>
                  <div className="footer">
                    <button
                      type="button"
                      className="close-calendar ms-auto"
                      onClick={() => setOpenCalendar(false)}
                    >
                      Cerrar
                    </button>
                  </div>
                </Popover>
              </Overlay>
            </div>

            <div className="smu-reload">
              <button
                type="button"
                className="btn-calendar my-auto fs-6 px-3"
                onClick={(event) => reloadData()}
              >
                <IconReload color="#E31C19" />
              </button>
            </div>
          </div>

          <div className="divider ms-lg-auto" />

          <div className="d-flex">
            <SmuProfile
              avatar={( loggedUser?.avatar && !loggedUser?.avatar.includes("null")) ? loggedUser?.avatar : defaultUser}
              name={loggedUser?.name || loggedUser?.username}
              options={isAdmin ? profileOptions : []}
              admin={isAdmin}
            />
          </div>
        </div>
      </Navbar>

      <Navbar bg="white" className="p-3 mt-2 d-block d-lg-none">
        <TotalSalesSummary />
      </Navbar>
    </>
  );
};

export default Topbar;
