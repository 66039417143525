import React, { useContext } from "react";
import { Navbar } from "react-bootstrap";
import "../../../assets/styles/topbar.scss";
import SmuBadge from "../../../util/smu-badge/SmuBadge";
import SmuProfile from "../../../util/smu-profile/SmuProfile";
import defaultUser from "../../../assets/images/user_default.png";
import { IconHome } from "../../../icons/Icon";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../../store/StoreContext";
import { SMU_INDEX_PATH } from "../../../constants";

const Topbar = () => {
  const navigate = useNavigate();
  const { setShowPreload, loggedUser } = useContext(StoreContext);

  const handleAction = () => {
    setShowPreload(true);
    const indexPath = localStorage.getItem(SMU_INDEX_PATH);
    const timeout = setTimeout(() => {
      navigate(indexPath);
      setShowPreload(false);
      clearTimeout(timeout);
    }, 1500);
  };

  const profileOptions = [
    {
      id: 1,
      title: "Ir a Ventas Online",
      Icon: <IconHome color="#33383F" />,
      action: handleAction,
    },
  ];

  return (
    <Navbar className="topbar-admin">
      <div className="d-flex mx-lg-4 w-100">
        <div className="d-flex my-auto h-100 pe-lg-4">
          <SmuBadge _className="rounded my-auto me-3" color="#E31C19" />

          <div className="text-white text-truncate my-auto">
            <span className="h5 fw-bolder m-0 me-2">DASHBOARD /</span>
            <span className="fw-bolder">Venta Online</span>
          </div>
        </div>
        <SmuProfile
          avatar={( loggedUser?.avatar && !loggedUser?.avatar.includes("null")) ? loggedUser?.avatar : defaultUser}
          name={loggedUser?.name || loggedUser?.username}
          options={profileOptions}
          _className="ms-auto"
        />
      </div>
    </Navbar>
  );
};

export default Topbar;
