import React from "react";

const IconShoppingBag = ({ color = "#e31c19" }) => {
  return (
    <svg
      id="UI_icon_shopping_bag_light"
      data-name="UI icon/shopping_bag/light"
      xmlns="http://www.w3.org/2000/svg"
      width="14.403"
      height="15"
      viewBox="0 0 14.403 15"
    >
      <path
        id="Union"
        d="M12.152,15H2.25A2.25,2.25,0,0,1,.017,12.471l.938-7.5A2.254,2.254,0,0,1,3.188,3H4.2a3,3,0,0,1,6,0h1.014a2.254,2.254,0,0,1,2.233,1.971l.938,7.5A2.25,2.25,0,0,1,12.152,15ZM3.188,4.5a.752.752,0,0,0-.745.657l-.938,7.5a.75.75,0,0,0,.745.843h9.9a.75.75,0,0,0,.745-.843l-.938-7.5a.752.752,0,0,0-.745-.657H10.2v.75a.75.75,0,1,1-1.5,0V4.5h-3v.75a.75.75,0,1,1-1.5,0V4.5ZM7.2,1.5A1.5,1.5,0,0,0,5.7,3h3A1.5,1.5,0,0,0,7.2,1.5Z"
        transform="translate(0)"
        fill={color}
      />
    </svg>
  );
};

export default IconShoppingBag;
