import React from "react";

const IconOptions = ({ color = "#e31c19" }) => {
  return (
    <svg
      id="UI_icon_more_vertical_filled"
      data-name="UI icon/more_vertical/filled"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Ellipse_31"
        data-name="Ellipse 31"
        d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"
        transform="translate(6 0)"
        fill={color}
      />
      <path
        id="Ellipse_32"
        data-name="Ellipse 32"
        d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"
        transform="translate(6 6)"
        fill={color}
      />
      <path
        id="Ellipse_33"
        data-name="Ellipse 33"
        d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"
        transform="translate(6 12)"
        fill={color}
      />
    </svg>
  );
};

export default IconOptions;
