import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import SmuPreload from "../../util/smu-preload/SmuPreload";
import Topbar from "../../components/topbar/admin/Topbar";
import { StoreContext } from "../../store/StoreContext";

const conteinerStyle = { maxWidth: "100%", zIndex: 1 };

const AdminLayout = () => {
  const { showPreload, isAdmin } = useContext(StoreContext);
  const navigate = useNavigate();

  useEffect(() => {
    if(!isAdmin) navigate('/portal-sales/sales')
  }, [])

  return (
    <>
      <SmuPreload show={showPreload} />

      <div className="w-100 smu-bg-body">
        <div className="admin-wrapper scroll-container">
          <div className="top-bg" />
          <Topbar />

          <Container className="px-0 mt-4 mx-0 h-100" style={conteinerStyle}>
            <Row className="h-100">
              <Col col={12} className="col-10 mx-auto">
                <Outlet />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
