import React from "react";

const IconCheveronLeft = ({ color = "#e31c19" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.247"
      height="13.992"
      viewBox="0 0 9.247 13.992"
    >
      <g id="Cheveron" transform="matrix(-1, 0.017, -0.017, -1, 8.056, 12.685)">
        <path
          id="Vector_1"
          data-name="Vector 1"
          d="M.244,11.422a.834.834,0,0,1,0-1.178L4.655,5.833.244,1.423,1.423.244l5,5a.833.833,0,0,1,0,1.178l-.589-.589.589-.589-.589.589.589.589-5,5a.834.834,0,0,1-1.179,0ZM.244.244a.835.835,0,0,1,1.179,0L.244,1.423A.835.835,0,0,1,.244.244Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default IconCheveronLeft;
