import React, { useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IconShoppingBag } from "../../icons/Icon";
import iconUp from "../../assets/images/icon-up.png";
import iconDown from "../../assets/images/icon-down.png";
import { logos } from "../../constants/logos";
import SmuTable from "../../util/smu-table/SmuTable";
import { outMM } from "../../helpers";

const BusinessSalesTable = ({
  onGraph,
  business,
  filter,
  currentBusiness,
  showButtonGraph,
  loading,
}) => {
  const columns = useMemo(
    () => [
      {
        name: "Negocio",
        selector: (row) => row.ORGANIZACION,
        cell: (row) => {
          return (
            <div
              className="cell form-check m-0 d-flex align-items-center"
              style={{ minHeight: 35, maxHeight: 35 }}
            >
              <input
                className="form-check-input"
                type="radio"
                name="business-sale"
                id={"sale" + row.ORGANIZACION}
                onChange={() => filter(row)}
                checked={row.ORGANIZACION === currentBusiness?.ORGANIZACION}
              />
              <label
                className="form-check-label"
                htmlFor={"sale" + row.ORGANIZACION}
              >
                <img
                  src={logos[row.ORGANIZACION.toLowerCase()]}
                  alt={row.ORGANIZACION}
                  className="ms-4"
                  {...(row.ORGANIZACION === "TOTAL" ? { width: 55 } : {})}
                />
              </label>
            </div>
          );
        },
      },
      {
        name: "Ppto MM",
        selector: (row) => row.VENTA_ACUM,
        cell: (row) => <div className="cell">{outMM(row.VENTA_ACUM)}</div>,
      },
      {
        name: "Venta Día MM",
        selector: (row) => row.VENTA,
        cell: (row) => (
          <div
            className="cell"
            style={{
              color: parceSale(row.VENTA) >= parceSale(row.VENTA_ACUM) ? "#83bf6e" : "#e31c19",
            }}
          >
            {outMM(row.VENTA)}
          </div>
        ),
      },
      {
        name: "SSS %",
        selector: (row) => row.SSS,
        cell: (row) => (
          <div
            className="cell d-flex align-items-center"
            style={{ whiteSpace: "nowrap" }}
          >
            <img
              src={row.SSS >= 0 ? iconUp : iconDown}
              alt={row.SSS}
              className="me-3"
            />
            <div
              style={{
                color: row.SSS >= 0 ? "#83bf6e" : "#e31c19",
              }}
            >
              {row.SSS}
            </div>
          </div>
        ),
      },
      {
        name: "SSS A %",
        selector: (row) => row.SSS_ACUM,
        cell: (row) => (
          <div
            className="cell d-flex align-items-center"
            style={{ whiteSpace: "nowrap" }}
          >
            <img
              src={row.SSS_ACUM >= 0 ? iconUp : iconDown}
              alt={row.SSS_ACUM}
              className="me-3"
            />
            <div
              style={{
                color: row.SSS_ACUM >= 0 ? "#83bf6e" : "#e31c19",
              }}
            >
              {row.SSS_ACUM}
            </div>
          </div>
        ),
      },
    ],
    [currentBusiness]
  );

  const parceSale = (sale) => {
    let _sale = sale.replace(/,/g, "");
    _sale = outMM(_sale);
    return Number(_sale);
  };

  return (
    <Card className="smu-card">
      <Card.Header className="border-0 bg-transparent">
        <Row>
          <Col col={6}>
            <div className="d-flex">
              <div className="smu-badge smu-bg-blue-light" />
              <div className="fs-5 ms-3 smu-text-dark">Venta Online</div>
            </div>
          </Col>
          {showButtonGraph && (
            <Col col={6} className="ms-auto d-flex d-lg-none">
              <button
                type="button"
                className="ms-auto px-3 btn-see-graph"
                onClick={() => onGraph()}
              >
                <IconShoppingBag color="#E31C19" />{" "}
                <span className="ms-2">Ver Gráfico</span>
              </button>
            </Col>
          )}
        </Row>
      </Card.Header>
      <Card.Body>
        <SmuTable
          columns={columns}
          data={business}
          className=""
          loading={loading}
        />
      </Card.Body>
    </Card>
  );
};

export default BusinessSalesTable;
