import React, { useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IconArrowsRightLeft } from "../../icons/Icon";
import graphLineIcon from "../../assets/images/graph-line-icon.png";
import SmuPaginator from "../../util/smu-paginator/SmuPaginator";
import { useSortBy, useTable } from "react-table/dist/react-table.development";
import { pascalCase, numberFormatter, priceFormatter } from "../../helpers";
import SmuTable from "../../util/smu-table/SmuTable";

const AccumulatedIndicatorsSummaryTable = ({ onSwith, sales, loading }) => {
  const columns = [
    {
      name: "Línea",
      selector: (row) => row.descLinea,
      cell: (row) => <div className="cell">{pascalCase(row.descLinea)}</div>,
    },
    {
      name: "Material",
      selector: (row) => row.descMat,
      cell: (row) => <div className="cell">{pascalCase(row.descMat)}</div>,
    },
    {
      name: "Un. Vendidas",
      selector: (row) => row.unidadesVendidasAcum,
      cell: (row) => (
        <div className="cell">
          {numberFormatter.format(row.unidadesVendidasAcum)}
        </div>
      ),
    },
    {
      name: "Tot. Ventas",
      selector: (row) => row.ventaTotalAcum,
      cell: (row) => (
        <div
          className="cell"
          style={{
            color: row.ventaTotalAcum >= 0 ? "#91C67F" : "e31c19",
          }}
        >
          {priceFormatter.format(row.ventaTotalAcum)}
        </div>
      ),
    },
    {
      name: "Cant. Ticket",
      selector: (row) => row.cantidadDeTicketAcum,
      cell: (row) => (
        <div className="cell">
          {numberFormatter.format(row.cantidadDeTicketAcum)}
        </div>
      ),
    },
  ];

  return (
    <Card className="smu-card">
      <Card.Header className="border-0 bg-transparent">
        <Row>
          <Col col={12} xs={10} lg={5} className="mb-4 mb-lg-0">
            <div className="d-flex align-items-center">
              <img src={graphLineIcon} alt="ICON" />
              <div className="fs-5 ms-3 smu-text-dark">
                Indicadores Acumulados
              </div>
            </div>
          </Col>
          <Col col={12} xs={2} className="d-lg-none">
            <div className="d-flex">
              <button
                type="button"
                className="btn-swith ms-auto"
                style={{ backgroundColor: "#FC5E5C" }}
                onClick={() => onSwith((val) => !val)}
              >
                <IconArrowsRightLeft color="#fff" />
              </button>
            </div>
          </Col>
          <Col col={12} lg={5}>
            <div className="d-flex">
              <div className="fs-5 me-auto ms-lg-auto me-lg-0 smu-text-dark">
                | Resumen por PLU
              </div>
            </div>
          </Col>
          <Col col={12} lg={2} className="d-none d-lg-block">
            <div className="d-flex">
              <button
                type="button"
                className="btn-swith ms-auto"
                style={{ backgroundColor: "#FC5E5C" }}
                onClick={() => onSwith((val) => !val)}
              >
                <IconArrowsRightLeft color="#fff" />
              </button>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <SmuTable
          columns={columns}
          data={sales}
          className="purple-table"
          loading={loading}
        />
      </Card.Body>
    </Card>
  );
};

export default AccumulatedIndicatorsSummaryTable;
