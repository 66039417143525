import React, { useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IconArrowsRightLeft } from "../../icons/Icon";
import activityIcon from "../../assets/images/activity-icon.png";
import Select from "../select/Select";
import { pascalCase, numberFormatter, priceFormatter } from "../../helpers";
import SmuTable from "../../util/smu-table/SmuTable";
import { useEffect } from "react";
import _ from "lodash";

const DailyIndicatorsTable = ({
  onSwith,
  sales,
  business,
  sections,
  lines,
  businessId,
  setBusinessId,
  sectionId,
  setSectionId,
  lineId,
  setLineId,
  loading,
}) => {
  const columns = useMemo(
    () => [
      {
        name: "Categoría",
        selector: (row) => row.descCategoria,
        sortable: true,
        cell: (row) => (
          <div className="cell">{pascalCase(row.descCategoria)}</div>
        ),
      },
      {
        name: "Stock Tot. Un.",
        selector: (row) => row.stockDiarioDisponible,
        sortable: true,
        cell: (row) => (
          <div className="cell">
            {numberFormatter.format(row.stockDiarioDisponible)}
          </div>
        ),
      },
      {
        name: "Un. Vendidas",
        selector: (row) => row.unidadesVendidasDiario,
        sortable: true,
        cell: (row) => (
          <div className="cell">
            {numberFormatter.format(row.unidadesVendidasDiario)}
          </div>
        ),
      },
      {
        name: "Tot. Ventas",
        selector: (row) => row.ventaTotalDiario,
        sortable: true,
        cell: (row) => (
          <div
            className="cell"
            style={{
              color: row.ventaTotalDiari >= 0 ? "#91C67F" : "e31c19",
            }}
          >
            {priceFormatter.format(row.ventaTotalDiario)}
          </div>
        ),
      },
      {
        name: "% Parti",
        selector: (row) => row.poncentParti,
        sortable: true,
        cell: (row) => (
          <div className="cell" style={{ whiteSpace: "nowrap" }}>
            {row.poncentParti} %
          </div>
        ),
      },
      {
        name: "Cant. Ticket",
        selector: (row) => row.cantidadDeTicketDiario,
        sortable: true,
        cell: (row) => (
          <div className="cell">
            {numberFormatter.format(row.cantidadDeTicketDiario)}
          </div>
        ),
      },
    ],
    []
  );

  const data = _.map(sales, (s) => ({
    ...s,
    stockDiarioDisponible: Number(s.stockDiarioDisponible),
    unidadesVendidasDiario: Number(s.unidadesVendidasDiario),
    cantidadDeTicketDiario: Number(s.cantidadDeTicketDiario),
  }));

  return (
    <Card className="smu-card">
      <Card.Header className="border-0 bg-transparent">
        <Row className="d-flex align-items-center">
          <Col col={12} xs={9} lg={4} className="mb-4 mb-lg-0">
            <div className="d-flex">
              <img src={activityIcon} alt="ICON" />
              <div className="fs-5 ms-3 smu-text-dark">Indicadores Diarios</div>
            </div>
          </Col>
          <div className="d-flex d-lg-none ms-auto w-auto mb-4">
            <button
              type="button"
              className="btn-swith ms-auto"
              style={{ backgroundColor: "#8C96F6" }}
              onClick={() => onSwith((val) => !val)}
            >
              <IconArrowsRightLeft color="#fff" />
            </button>
          </div>

          <Col col={12} lg={6} className="me-3">
            <Row className="d-flex">
              <Col>
                <Select
                  _className="indicators-select mx-3"
                  defaultOption="Negocio"
                  options={business}
                  value={businessId}
                  onChange={({ target }) => setBusinessId(target.value)}
                />
              </Col>
              <Col>
                <Select
                  _className="indicators-select mx-3"
                  defaultOption="Sección"
                  options={sections}
                  value={sectionId}
                  onChange={({ target }) => setSectionId(target.value)}
                />
              </Col>
              <Col>
                <Select
                  _className="indicators-select mx-3"
                  defaultOption="Línea"
                  options={lines}
                  value={lineId}
                  onChange={({ target }) => setLineId(target.value)}
                />
              </Col>
            </Row>
          </Col>

          <div className="d-none d-lg-flex w-auto ms-auto">
            <button
              type="button"
              className="btn-swith ms-auto"
              style={{ backgroundColor: "#8C96F6" }}
              onClick={() => onSwith((val) => !val)}
            >
              <IconArrowsRightLeft color="#fff" />
            </button>
          </div>
        </Row>
      </Card.Header>
      <Card.Body>
        <SmuTable
          columns={columns}
          data={data}
          loading={loading}
          className="red-table"
        />
      </Card.Body>
    </Card>
  );
};

export default DailyIndicatorsTable;
