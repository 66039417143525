import React from "react";

const IconX = ({ color = "#e31c19" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <path
        id="Union"
        d="M1.463.251A.857.857,0,1,0,.251,1.463L4.788,6,.251,10.537a.857.857,0,0,0,1.212,1.212L6,7.212l4.537,4.537a.857.857,0,0,0,1.212-1.212L7.212,6l4.537-4.537A.857.857,0,0,0,10.537.251L6,4.788Z"
        transform="translate(0 0)"
        fill={color}
      />
    </svg>
  );
};

export default IconX;
