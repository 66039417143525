import React from "react";

const RowItem = ({ children, active, _onClick }) => {
  return (
    <tr
      className={`c-pointer ${active ? "tr-selected" : ""}`}
      onClick={_onClick}
    >
      {children}
    </tr>
  );
};

export default RowItem;
