import React from "react";
import { IconSearch } from "../../icons/Icon";
import "../../assets/styles/search.scss";

export const Search = ({ value, onChange }) => {
  return (
    <div className="smu-search px-3 ms-auto my-auto">
      <input
        text="serach"
        placeholder="Buscar"
        value={value}
        onChange={onChange}
      />
      <IconSearch />
    </div>
  );
};
