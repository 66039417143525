import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import "../../assets/styles/smu-profile-photo-editor.scss";
import { IconPencilEdit } from "../../icons/Icon";
import userDefault from "../../assets/images/user_default.png"

const SmuProfilePhotoEditor = ({ styles, avatar, file, setFile }) => {
  const [image, setImage] = useState("");

  useEffect(() => {
    let _image = avatar || userDefault;
    _image = _image.includes("null") ? userDefault : avatar;
    if (!!file) _image = URL.createObjectURL(file);
    setImage(_image);
  }, [file]);

  return (
    <div className="smu-profile-photo-editor" style={{ ...styles }}>
      <Image src={image} roundedCircle className="mx-auto w-100 h-100" />
      <label className="editor-control" htmlFor="avatar">
        <div className="btn-edit shadow-sm">
          <IconPencilEdit color="#33383F" />
        </div>
        <div className="text-nowrap">Subir foto</div>
        <input
          type="file"
          id="avatar"
          hidden
          onChange={(event) => {
            setFile(event.target.files[0]);
          }}
          accept=".jpg,.png,.jpeg"
        />
      </label>
    </div>
  );
};

export default SmuProfilePhotoEditor;
