import React, { useContext, useState, useRef } from "react";
import "../assets/styles/access.scss";
import { Col, Container, Row } from "react-bootstrap";
import logov1 from "../assets/images/logov1.png";
import graphicIcon from "../assets/images/graphic-icon.png";
import userIcon from "../assets/images/user-icon.svg";
import passIcon from "../assets/images/pass-icon.svg";
import { useNavigate } from "react-router-dom";
import { signIn } from "../api/auth";
import { SMU_INDEX_PATH, SMU_TOKEN, SMU_USER } from "../constants";
import { toast } from "../helpers/smu-alerts/smu-lerts";
import loadingGif from "../assets/images/loading.gif";
import _ from "lodash";
import { StoreContext } from "../store/StoreContext";
import jwtDecode from "jwt-decode";
import ReCAPTCHA from "react-google-recaptcha"
import useAuth from "../hooks/useAuth"

const Access = () => {
  const navigate = useNavigate();
  const { setLoggedUser, setPermissions } = useContext(StoreContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { validate } = useAuth();

  const captchaRef = useRef(null)

  const trySignIn = (event) => {
    event.preventDefault();
    setLoading(true);

    const token = captchaRef.current.getValue();
    captchaRef.current.reset();

    signIn(username, password, token)
      .then(( response ) => {

        const info = response.data;
        const token = jwtDecode(info.token);
        const permission = token?.permission || {};
        
        
        let path = "/portal-sales";
        if (permission.venta_online == true) {
          path += "/sales";
        } else if (permission.metricas_operactionales == true) {
          path += "/operational-metrics";
        } else if (permission.monitor_promocional == true) {
          path += "/promotional-monitor";
        } else {
          throw new Error();
        }

        const data = {token: info.token, user: info.users, path: path}
        
        validate({ data });

        setLoading(false);
        navigate(path, { replace: true });
      })
      .catch((error) => {
        setUsername("");
        setPassword("");
        setLoading(false);
        toast({
          icon: "error",
          title: "Usuario o contraseña incorrectos.",
        });
      });
  };

  return (
    <Container className="py-5">
      <Row className="pb-5 px-4 h-100">
        <Col col={12} md={5} lg={4} className="ms-auto">
          <div className="d-flex flex-column py-4">
            <img src={logov1} alt="LOGO V1" className="logo-v1 my-4" />

            <div className="d-flex align-items-center my-5">
              <img
                src={graphicIcon}
                alt="GRAPHIC ICON"
                className="graphic-icon me-3"
              />
              <span className="text-graphic">Venta Online</span>
            </div>
          </div>
        </Col>

        <Col col={12} md={1}>
          <div className="d-flex h-100">
            <div className="divider-vertical d-none d-md-block" />
            <div className="divider-horizontal d-block d-md-none" />
          </div>
        </Col>
        <Col col={12} md={5} lg={4} className="me-auto">
          <div className="d-flex h-100">
            <form
              className="mt-auto w-100"
              onSubmit={(event) => trySignIn(event)}
            >
              <div className="title my-3 text-left">
                Ingrese sus datos para iniciar sesión
              </div>
              <div className="control">
                <img src={userIcon} alt="ICON" />
                <input
                  type="text"
                  placeholder="Usuario"
                  value={username}
                  onChange={({ target }) => setUsername(target.value)}
                />
              </div>
              <div className="control">
                <img src={passIcon} alt="ICON" />
                <input
                  type="password"
                  placeholder="Contraseña"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                />
              </div>

              <div className="recaptcha">
                <ReCAPTCHA
                  sitekey="6Lcot5wjAAAAAOfMyeLVJ9BiM-mrXV4pJjtPveSy"
                  ref={captchaRef}
                />
              </div>
              {!loading ? (
                <button type="submit" className="btn-signin mb-5">
                  Iniciar Sesión
                </button>
              ) : (
                <div className="signin-loading mb-5">
                  <img src={loadingGif} alt="Cargando" />
                  <span className="ms-3">Cargando...</span>
                </div>
              )}
            </form>
          </div>
        </Col>
        <div className="footer mt-5 mt-md-auto">
          <div className="text-footer mx-auto">DWH Corporativo V2.r2.0</div>
        </div>
      </Row>
    </Container>
  );
};

export default Access;
