import alvi from "../assets/images/logos/alvi.png";
import mayorista10 from "../assets/images/logos/mayorista10.png";
import okmarket from "../assets/images/logos/okmarket.png";
import smu from "../assets/images/logos/smu.png";
import super10 from "../assets/images/logos/super10.png";
import unimarc from "../assets/images/logos/unimarc.png";

const logos = {
  alvi,
  "mayorista 10": mayorista10,
  "ok market": okmarket,
  total: smu,
  "super 10": super10,
  unimarc,
};

export { logos };
