import React, { useContext, useEffect, useState } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import LocalSaleTable from "../../../components/local-sale-table/LocalSaleTable";
import ManagerSaleTable from "../../../components/manager-sale-table/ManagerSaleTable";
import BusinessSaleTable from "../../../components/business-sale-table/BusinessSaleTable";
import { StoreContext } from "../../../store/StoreContext";
import ModalGraph from "../../../components/modal-graph/ModalGraph";
import moment from "moment";
import LineGraph from "../../../util/smu-graph/LineGraph";
import _ from "lodash";
import { outMM } from "../../../helpers";

const tabs = [
  { id: "tab-all", title: "Filtro | Todo" },
  { id: "tab-gte", title: "Filtro | Gte. Zonal" },
];
const defaultCurrentBusiness = {
  ORGANIZACION: "TOTAL",
};

const Sales = () => {
  const { setViewTitle, business, managers, locals, date, loading } =
    useContext(StoreContext);

  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [showGraph, setShowGraph] = useState(false);

  const [currentBusiness, setCurrentBusiness] = useState(
    defaultCurrentBusiness
  );
  const [currentManager, setCurrentManager] = useState(null);
  const [localsByManager, setLocalsByManager] = useState([]);
  const [handleManagers, setHandleManagers] = useState([]);
  const [lineGraphLabelsManagers, setLineGraphLabelsManagers] = useState([]);
  const [lineGraphDataManagers, setLineGraphDataManagers] = useState([]);
  const [lineGraphLabelsLocals, setLineGraphLabelsLocals] = useState([]);
  const [lineGraphDataLocals, setLineGraphDataLocals] = useState([]);
  const [lineGraphLabelsTotal, setLineGraphLabelsTotal] = useState([]);
  const [lineGraphDataTotal, setLineGraphDataTotal] = useState([]);

  const tabGte = activeTab !== tabs[0].id ? "d-none d-lg-block" : "";
  const tabFilter = activeTab !== tabs[1].id ? "d-none d-lg-block" : "";
  const showSeeGraphInTable = !!currentBusiness && !!currentManager;

  useEffect(() => {
    let _managers = managers;
    _managers = _managers.map((m) => ({
      ...m,
      DESV: Number(outMM(m.DESV.replace(/,/g, ""))),
      VENTA: Number(outMM(m.VENTA.replace(/,/g, ""))),
    }));
    _managers = _.orderBy(_managers, ["VENTA"], ["desc"]);
    
    let _labels = [];
    const _lineGraphData = [
      { label: "Presupuesto", color: "#FF6A55", data: [] },
      { label: "Venta", color: "#2A85FF", data: [] },
    ];

    if (currentBusiness.ORGANIZACION !== defaultCurrentBusiness.ORGANIZACION) {
      _managers = _managers.filter((_manager) => {
        return _manager.ORGANIZACION === currentBusiness.ORGANIZACION;
      });
      _labels = _managers.map((_manager) => _manager.GTE_MERC);

      _lineGraphData[0].data = _managers.map((_manager) => _manager.DESV);
      _lineGraphData[1].data = _managers.map((_manager) => _manager.VENTA);
    }

    setCurrentManager(null);
    setHandleManagers(_managers);
    setLineGraphLabelsManagers(_labels);
    setLineGraphDataManagers(_lineGraphData);
    setLocalsByManager([]);
  }, [currentBusiness, managers]);

  useEffect(() => {
    let _labels = [];
    const _lineGraphData = [
      { label: "Presupuesto", color: "#FF6A55", data: [] },
      { label: "Venta", color: "#2A85FF", data: [] },
    ];

    business.forEach((b) => {
      let ppto = outMM(b.VENTA_ACUM.replace(/,/g, ""));
      ppto = Number(ppto);

      let sale = outMM(b.VENTA.replace(/,/g, ""));
      sale = Number(sale);

      _lineGraphData[0].data.push(ppto);
      _lineGraphData[1].data.push(sale);
      _labels.push(b.ORGANIZACION);
    });

    setLineGraphLabelsTotal(_labels);
    setLineGraphDataTotal(_lineGraphData);
    setLocalsByManager([]);

  }, [business]);

  const filterByManager = (_manager) => {
    let _currentManager = null;
    let _localsByManager = [];
    let _labels = [];

    if( typeof(_manager) == 'string')
    {
      _manager = managers.filter(_m => _m.GTE_MERC == _manager)[0]
    } 

    const _lineGraphData = [
      { label: "Presupuesto", color: "#FF6A55", data: [] },
      { label: "Venta", color: "#2A85FF", data: [] },
    ];

    if (currentManager?.GTE_MERC !== _manager.GTE_MERC) {
      _currentManager = _manager;
      _localsByManager = locals.filter((l) => {
        return l.GTE_MERC === _currentManager.GTE_MERC;
      });
      _localsByManager = _localsByManager.map((l) => ({
        ...l,
        VENTA: Number(outMM(l.VENTA)),
        DESV: Number(outMM(l.DESV.replace(/,/g, ""))),
      }));
      _localsByManager = _.orderBy(_localsByManager, ["VENTA"], ["desc"]);

      _labels = _localsByManager.map((sbm) => sbm.LOCAL);
      _lineGraphData[1].data = _localsByManager.map((sbm) => sbm.VENTA);
      _lineGraphData[0].data = _localsByManager.map((sbm) => sbm.DESV);
    }

    setLineGraphLabelsLocals(_labels);
    setLineGraphDataLocals(_lineGraphData);
    setLocalsByManager(_localsByManager);
    setCurrentManager(_currentManager);
  };

  return (
    <>
      <ModalGraph show={showGraph} onHide={setShowGraph}>
        {currentBusiness.ORGANIZACION === defaultCurrentBusiness.ORGANIZACION &&
          !currentManager && (
            <LineGraph
              title="Gráfico Venta Online"
              labels={lineGraphLabelsTotal}
              data={lineGraphDataTotal}
            />
          )}
        {currentBusiness.ORGANIZACION !== defaultCurrentBusiness.ORGANIZACION &&
          !currentManager && (
            <LineGraph
              title="Gráficos"
              labels={lineGraphLabelsManagers}
              data={lineGraphDataManagers}
              loading={loading}
            />
          )}
        {!!currentBusiness && !!currentManager && (
          <LineGraph
            title="Gráficos"
            labels={lineGraphLabelsLocals}
            data={lineGraphDataLocals}
            loading={loading}
          />
        )}
      </ModalGraph>

      <Row className="m-0">
        <Col col={12} lg={7} className="mb-3 p-0 pe-lg-2">
          <BusinessSaleTable
            onGraph={() => setShowGraph((val) => !val)}
            business={business}
            filter={setCurrentBusiness}
            currentBusiness={currentBusiness}
            showButtonGraph={!showSeeGraphInTable}
            loading={loading}
          />
        </Col>
        <Col col={12} lg={5} className="mb-3 p-0 ps-lg-2">
          {currentBusiness.ORGANIZACION ===
            defaultCurrentBusiness.ORGANIZACION &&
            !currentManager && (
              <LineGraph
                title="Gráfico Venta Online"
                labels={lineGraphLabelsTotal}
                data={lineGraphDataTotal}
              />
            )}
          {currentBusiness.ORGANIZACION !==
            defaultCurrentBusiness.ORGANIZACION &&
            !currentManager && (
              <LineGraph
                title={"Gráficos | " + currentBusiness.ORGANIZACION}
                labels={lineGraphLabelsManagers}
                data={lineGraphDataManagers}
                loading={loading}
              />
            )}
          {!!currentManager && (
            <LineGraph
              title={`Gráficos | ${currentManager?.GTE_MERC}`}
              labels={lineGraphLabelsLocals}
              data={lineGraphDataLocals}
              loading={loading}
            />
          )}
        </Col>
      </Row>

      <Nav
        className="d-lg-none smu-tabs"
        variant="tabs"
        activeKey={activeTab}
        onSelect={setActiveTab}
      >
        {tabs.map((tab) => (
          <Nav.Item key={tab.id}>
            <Nav.Link eventKey={tab.id}>{tab.title}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      <Row className="m-0">
        <Col col={12} lg={7} className={`mb-3 p-0 pe-lg-2 ${tabGte}`}>
          <ManagerSaleTable
            businessName={currentBusiness?.ORGANIZACION}
            onGraph={() => setShowGraph((val) => !val)}
            managers={handleManagers}
            filter={filterByManager}
            manager={currentManager}
            showButtonGraph={showSeeGraphInTable}
            loading={loading}
          />
        </Col>
        <Col col={12} lg={5} className={`mb-3 p-0 ps-lg-2 ${tabFilter}`}>
          <LocalSaleTable
            onGraph={() => setShowGraph((val) => !val)}
            managers={handleManagers}
            filter={filterByManager}
            locals={localsByManager}
            manager={currentManager}
            showButtonGraph={showSeeGraphInTable}
            businessName={currentBusiness?.ORGANIZACION}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default Sales;
