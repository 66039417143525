import React from "react";
import { Col, Row } from "react-bootstrap";
import "../../assets/styles/smu-preload.scss";
import percentageIcon from "../../assets/images/graphic-icon.png";

const SmuPreload = ({ show }) => {
  return (
    <div className={`smu-preload ${!show ? "d-none" : ""}`}>
      <Row className="my-auto">
        <Col col={12} md={4} className="mx-auto d-flex flex-column">
          <img src={percentageIcon} alt="Icon" className="mx-auto" width={80} />
          <h3 className="text-white mt-4 text-center">Home</h3>
          <h3 className="text-white mt-1 text-center">Venta Online</h3>
        </Col>
      </Row>
    </div>
  );
};

export default SmuPreload;
