import React, { createContext, useState } from "react";

const defaultValues = {
  loggedUser: null,

  date: new Date(),
  loading: false,

  viewTitle: null,
  showPreload: false,

  business: [],
  managers: [],
  locals: [],
  promotionSummary: null,
  promotionsSale: [],
  promotionsPlu: [],
  isAdmin: false
};

export const StoreContext = createContext(defaultValues);

const StoreProvider = ({ children }) => {
  const [loggedUser, setLoggedUser] = useState(defaultValues.loggedUser);
  const [permissions, setPermissions] = useState(defaultValues.permissions);
  const [isAdmin, setIsAdmin] = useState(defaultValues.isAdmin);

  const [date, setDate] = useState(defaultValues.date);
  const [loading, setLoading] = useState(defaultValues.loading);

  const [viewTitle, setViewTitle] = useState(defaultValues.viewTitle);
  const [showPreload, setShowPreload] = useState(defaultValues.showPreload);

  const [business, setBusiness] = useState(defaultValues.business);
  const [managers, setManagers] = useState(defaultValues.managers);
  const [locals, setLocals] = useState(defaultValues.locals);
  const [promotionSummary, setPromotionSummary] = useState(
    defaultValues.promotionSummary
  );
  const [promotionsSale, setPromotionsSale] = useState(
    defaultValues.promotionsSale
  );
  const [promotionsPlu, setPromotionsPlu] = useState(
    defaultValues.promotionsPlu
  );

  const values = {
    loggedUser,
    setLoggedUser,

    permissions,
    setPermissions,

    isAdmin,
    setIsAdmin,

    date,
    setDate,
    loading,
    setLoading,

    viewTitle,
    setViewTitle,
    showPreload,
    setShowPreload,

    business,
    setBusiness,
    managers,
    setManagers,
    locals,
    setLocals,
    promotionSummary,
    setPromotionSummary,
    promotionsSale,
    setPromotionsSale,
    promotionsPlu,
    setPromotionsPlu,
  };

  return (
    <StoreContext.Provider value={values}>{children}</StoreContext.Provider>
  );
};

export default StoreProvider;
