import React from "react";

const IconCalendar = ({ color = "#e31c19" }) => {
  return (
    <svg
      id="UI_icon_calendar_light"
      data-name="UI icon/calendar/light"
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <path
        id="Union"
        d="M12.75,15H2.25A2.252,2.252,0,0,1,0,12.75v-9A2.253,2.253,0,0,1,2.25,1.5H3V.75a.75.75,0,0,1,1.5,0V1.5h6V.75a.75.75,0,0,1,1.5,0V1.5h.75A2.253,2.253,0,0,1,15,3.75v9A2.252,2.252,0,0,1,12.75,15ZM1.5,7.5v5.25a.751.751,0,0,0,.75.75h10.5a.751.751,0,0,0,.75-.75V7.5ZM2.25,3a.751.751,0,0,0-.75.751V6h12V3.75A.751.751,0,0,0,12.75,3H12V3.75a.75.75,0,0,1-1.5,0V3h-6V3.75a.75.75,0,0,1-1.5,0V3Z"
        fill={color}
      />
    </svg>
  );
};

export default IconCalendar;
