import React from "react";

const IconArrowLeft = ({ color = "#e31c19" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        id="Union"
        d="M6.032,12.407a1.074,1.074,0,0,1,0,1.32.685.685,0,0,1-1.095,0L.453,8.32a2.148,2.148,0,0,1,0-2.64L4.938.273a.685.685,0,0,1,1.095,0,1.074,1.074,0,0,1,0,1.32L2.322,6.067h10.9A.865.865,0,0,1,14,7a.865.865,0,0,1-.774.933H2.322Z"
        transform="translate(0)"
        fill={color}
      />
    </svg>
  );
};

export default IconArrowLeft;
