import React from "react";

const IconSafe = ({ color = "#e31c19" }) => {
  return (
    <svg
      id="UI_icon_safe_light"
      data-name="UI icon/safe/light"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
    >
      <path
        id="Union"
        d="M16,18a2,2,0,0,1-2-2H6a2,2,0,0,1-4,0v-.171A3.005,3.005,0,0,1,0,13V3A3,3,0,0,1,3,0H17a3,3,0,0,1,3,3V13a3,3,0,0,1-2,2.83V16A2,2,0,0,1,16,18ZM3,2A1,1,0,0,0,2,3V13a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1Z"
        transform="translate(0)"
        fill={color}
      />
      <path
        id="Vector_605_Stroke_"
        data-name="Vector 605 (Stroke)"
        d="M0,.73A.73.73,0,0,1,.73,0H6.568a.73.73,0,1,1,0,1.46H.73A.73.73,0,0,1,0,.73Z"
        transform="translate(3.601 4.36)"
        fill={color}
      />
      <path
        id="Vector_607_Stroke_"
        data-name="Vector 607 (Stroke)"
        d="M0,.73A.73.73,0,0,1,.73,0H3.649a.73.73,0,1,1,0,1.46H.73A.73.73,0,0,1,0,.73Z"
        transform="translate(3.601 7.279)"
        fill={color}
      />
      <path
        id="Ellipse_201"
        data-name="Ellipse 201"
        d="M2.2,1.1A1.1,1.1,0,1,1,1.1,0,1.1,1.1,0,0,1,2.2,1.1Z"
        transform="translate(11.375 6.753)"
        fill={color}
      />
      <path
        id="Ellipse_202"
        data-name="Ellipse 202"
        d="M2.2,1.1A1.1,1.1,0,1,1,1.1,0,1.1,1.1,0,0,1,2.2,1.1Z"
        transform="translate(14.274 10.383)"
        fill={color}
      />
      <path
        id="Vector_617_Stroke_"
        data-name="Vector 617 (Stroke)"
        d="M4.839.169A.729.729,0,0,1,4.933,1.2L1.289,5.568a.729.729,0,1,1-1.12-.933L3.813.262A.729.729,0,0,1,4.839.169Z"
        transform="translate(11.375 6.753)"
        fill={color}
      />
    </svg>
  );
};

export default IconSafe;
