import React, { useContext, useEffect, useState, useCallback } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import DailyIndicatorsTable from "../../../components/daily-indicators-table/DailyIndicatorsTable";
import DailyIndicatorsSummaryTable from "../../../components/daily-indicators-summary-table/DailyIndicatorsSummaryTable";
import AccumulatedIndicatorsTable from "../../../components/accumulated-indicators-table/AccumulatedIndicatorsTable";
import AccumulatedIndicatorsSummaryTable from "../../../components/accumulated-indicators-summary-table/AccumulatedIndicatorsSummaryTable";
import SummaryTable from "../../../components/summary-table/SummaryTable";
import { StoreContext } from "../../../store/StoreContext";
import _ from "lodash";
import moment from "moment";

const tabs = [
  { id: "tab-i", title: "Indicadores" },
  { id: "tab-plu", title: "Resuman PLU" },
];

const PromotionalMonitor = () => {
  const {
    setViewTitle,
    promotionSummary,
    promotionsSale,
    promotionsPlu,
    date,
    loading,
  } = useContext(StoreContext);

  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [swith, setSwith] = useState(false);
  const [business, setBusiness] = useState([]);
  const [sections, setSections] = useState([]);
  const [lines, setLines] = useState([]);
  const [businessId, setBusinessId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [lineId, setLineId] = useState("");
  const [handlePromotionsSale, setHandlePromotionsSale] = useState([]);

  const tabI = activeTab !== tabs[0].id ? "d-none d-lg-block" : "";
  const tabPLU = activeTab !== tabs[1].id ? "d-none d-lg-block" : "";

  const sortIdRef = React.useRef(0);

  useEffect(() => {
    if (!promotionsSale.length) return;

    let _business = _.uniqBy(promotionsSale, "idNegocio");
    let _sections = _.uniqBy(promotionsSale, "idSeccion");
    let _lines = _.uniqBy(promotionsSale, "idLinea");

    _business = _business.map((b) => ({
      id: b.idNegocio,
      title: b.descNegocio,
    }));
    _sections = _sections.map((s) => ({
      id: s.idSeccion,
      title: s.descSeccion,
    }));
    _lines = _lines.map((l) => ({
      id: l.idLinea,
      title: l.descLinea,
    }));

    setBusiness(_business);
    setSections(_sections);
    setLines(_lines);

  }, [promotionsSale]);

  useEffect(() => {
    let _handlePromotionsSale = promotionsSale;

    if (!!businessId) {
      _handlePromotionsSale = _handlePromotionsSale.filter((ps) => {
        return ps.idNegocio === businessId;
      });
    }
    if (!!sectionId) {
      _handlePromotionsSale = _handlePromotionsSale.filter((ps) => {
        return ps.idSeccion === sectionId;
      });
    }
    if (!!lineId) {
      _handlePromotionsSale = _handlePromotionsSale.filter((ps) => {
        return ps.idLinea === lineId;
      });
    }

    setHandlePromotionsSale(_handlePromotionsSale);
  }, [promotionsSale, businessId, sectionId, lineId]);


  const handleSort = useCallback(({ sortBy, pageIndex, pageSize }) => {
    // Give this sort an ID
    const sortId = ++sortIdRef.current;

    //simulate remove server sort
    setTimeout(() => {
      // Doing multisort
      if (sortId === sortIdRef.current) {
        let sorted = promotionsPlu.slice();
        
        sorted.sort((a, b) => {
          for (let i = 0; i < sortBy.length; ++i) {
            if (a[sortBy[i].id] > b[sortBy[i].id])
              return sortBy[i].desc ? -1 : 1;
            if (a[sortBy[i].id] < b[sortBy[i].id])
              return sortBy[i].desc ? 1 : -1;
          }
          return 0;
        });
        const startRow = 20 * 1;
        const endRow = startRow + pageSize;
        setHandlePromotionsSale(sorted.slice(startRow, endRow));

      }
    }, 200);
  }, []);

  return (
    <>
      <Row className="m-0">
        <Col col={12} lg={6} className="mb-3 p-0 pe-lg-2">
          <SummaryTable summary={promotionSummary} loading={loading} />
        </Col>
        <Col col={12} lg={6} className={`mb-3 p-0 pe-lg-2 ${tabI}`}>
          {!swith ? (
            <DailyIndicatorsTable
              onSwith={setSwith}
              sales={handlePromotionsSale}
              business={business}
              sections={sections}
              lines={lines}
              businessId={businessId}
              setBusinessId={setBusinessId}
              sectionId={sectionId}
              setSectionId={setSectionId}
              lineId={lineId}
              setLineId={setLineId}
              loading={loading}
            />
          ) : (
            <AccumulatedIndicatorsTable
              onSwith={setSwith}
              sales={handlePromotionsSale}
              business={business}
              sections={sections}
              lines={lines}
              businessId={businessId}
              setBusinessId={setBusinessId}
              sectionId={sectionId}
              setSectionId={setSectionId}
              lineId={lineId}
              setLineId={setLineId}
              loading={loading}
            />
          )}
        </Col>
      </Row>

      <Nav
        className="d-lg-none smu-tabs"
        variant="tabs"
        activeKey={activeTab}
        onSelect={setActiveTab}
      >
        {tabs.map((tab) => (
          <Nav.Item key={tab.id}>
            <Nav.Link eventKey={tab.id}>{tab.title}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      <Row className="m-0">
        <Col col={12} lg={12} className={`mb-3 p-0 ps-lg-2 ${tabPLU}`}>
          {!swith ? (
            <DailyIndicatorsSummaryTable
              onSwith={setSwith}
              sales={promotionsPlu}
              loading={loading}
              onSort={handleSort}
            />
          ) : (
            <AccumulatedIndicatorsSummaryTable
              onSwith={setSwith}
              sales={promotionsPlu}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default PromotionalMonitor;
