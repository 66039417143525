import React, { useContext, useEffect, useState } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import ModalGraph from "../../../components/modal-graph/ModalGraph";
import ManagerMetricTable from "../../../components/manager-metric-table/ManagerMetricTable";
import BusinessMetricTable from "../../../components/business-metric-table/BusinessMetricTable";
import { StoreContext } from "../../../store/StoreContext";
import moment from "moment";
import BarGraph from "../../../util/smu-graph/BarGraph";
import LineGraph from "../../../util/smu-graph/LineGraph";
import LocalMetricTable from "../../../components/local-metric-table/LocalMetricTable";

const tabs = [
  { id: "tab-all", title: "Filtro | Todo" },
  { id: "tab-gte", title: "Filtro | Gte. Zonal" },
];
const barGraphData = [
  { label: "DHO", data: [], backgroundColor: "#B1E5FC" },
  { label: "In Stock", data: [], backgroundColor: "#EE8BF5" },
  { label: "NSG", data: [], backgroundColor: "#FFF29C" },
];
const defaultCurrentBusiness = {
  ORGANIZACION: "TOTAL",
};

const OperationalMetrics = () => {
  const { setViewTitle, business, managers, locals, date, loading } =
    useContext(StoreContext);

  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [showGraph, setShowGraph] = useState(false);

  const [currentBusiness, setCurrentBusiness] = useState(
    defaultCurrentBusiness
  );
  const [currentManager, setCurrentManager] = useState(null);
  const [localsByManager, setLocalsByManager] = useState([]);
  const [handleManagers, setHandleManagers] = useState([]);
  const [lineGraphLabelsManagers, setLineGraphLabelsManagers] = useState([]);
  const [lineGraphDataManagers, setLineGraphDataManagers] = useState([]);
  const [lineGraphLabelsLocals, setLineGraphLabelsLocals] = useState([]);
  const [lineGraphDataLocals, setLineGraphDataLocals] = useState([]);
  const [lineGraphLabelsTotal, setLineGraphLabelsTotal] = useState([]);
  const [lineGraphDataTotal, setLineGraphDataTotal] = useState([]);

  const tabAll = activeTab !== tabs[0]?.id ? "d-none d-lg-block" : "";
  const tabFilter = activeTab !== tabs[1]?.id ? "d-none d-lg-block" : "";
  const showSeeGraphInTable = !!currentBusiness && !!currentManager;

  useEffect(() => {
    let _managers = managers;
    let _labels = [];
    const _lineGraphData = [
      { label: "DOH", color: "#2A85FF", data: [] },
      { label: "In Stock", color: "#FF6A55", data: [] },
      { label: "NSG", color: "#B5E4CA", data: [] },
    ];

    if (currentBusiness.ORGANIZACION !== defaultCurrentBusiness.ORGANIZACION) {
      _managers = _managers.filter((_manager) => {
        return _manager.ORGANIZACION === currentBusiness.ORGANIZACION;
      });
      _labels = _managers.map((_manager) => _manager.GTE_MERC);
      _lineGraphData[0].data = _managers.map((_manager) => {
        return Number(_manager.DHO);
      });
      _lineGraphData[1].data = _managers.map((_manager) => {
        return Number(_manager.INSTOCK);
      });
      _lineGraphData[2].data = _managers.map((_manager) => {
        return Number(_manager.NSG);
      });
    }

    setCurrentManager(null);
    setHandleManagers(_managers);
    setLineGraphLabelsManagers(_labels);
    setLineGraphDataManagers(_lineGraphData);
  }, [currentBusiness, managers]);

  useEffect(() => {
    let _labels = [];
    const _lineGraphData = [
      { label: "DOH", color: "#2A85FF", data: [] },
      { label: "In Stock", color: "#FF6A55", data: [] },
      { label: "NSG", color: "#B5E4CA", data: [] },
    ];

    business.forEach((b) => {
      _labels.push(b.ORGANIZACION);

      let dho = Number(b.DHO);
      let inStock = Number(b.INSTOCK);
      let nsg = Number(b.NSG);

      _lineGraphData[0].data.push(dho);
      _lineGraphData[1].data.push(inStock);
      _lineGraphData[2].data.push(nsg);
    });

    setLineGraphLabelsTotal(_labels);
    setLineGraphDataTotal(_lineGraphData);
  }, [business]);

  const filterByManager = (_manager) => {
    let _currentManager = null;
    let _localsByManager = [];
    let _labels = [];
    const _lineGraphData = [
      { label: "DOH", color: "#2A85FF", data: [] },
      { label: "In Stock", color: "#FF6A55", data: [] },
      { label: "NSG", color: "#B5E4CA", data: [] },
    ];

    if (currentManager?.GTE_MERC !== _manager.GTE_MERC) {
      _currentManager = _manager;
      _localsByManager = locals.filter((l) => {
        return l.GTE_MERC === _currentManager.GTE_MERC;
      });
      _labels = _localsByManager.map((sbm) => sbm.LOCAL);
      _lineGraphData[0].data = _localsByManager.map((sbm) => {
        return Number(sbm.DHO);
      });
      _lineGraphData[1].data = _localsByManager.map((sbm) => {
        return Number(sbm.INSTOCK);
      });
      _lineGraphData[2].data = _localsByManager.map((sbm) => {
        return Number(sbm.NSG);
      });
    }

    setLineGraphLabelsLocals(_labels);
    setLineGraphDataLocals(_lineGraphData);
    setLocalsByManager(_localsByManager);
    setCurrentManager(_currentManager);
  };

  return (
    <>
      <ModalGraph show={showGraph} onHide={setShowGraph}>
        {currentBusiness.ORGANIZACION === defaultCurrentBusiness.ORGANIZACION &&
          !currentManager && (
            <LineGraph
              title="Gráfico Métricas Operacionales"
              labels={lineGraphLabelsTotal}
              data={lineGraphDataTotal}
            />
          )}
        {currentBusiness.ORGANIZACION !== defaultCurrentBusiness.ORGANIZACION &&
          !currentManager && (
            <LineGraph
              title="Gráficos"
              labels={lineGraphLabelsManagers}
              data={lineGraphDataManagers}
              loading={loading}
            />
          )}
        {!!currentBusiness && !!currentManager && (
          <LineGraph
            title="Gráficos"
            labels={lineGraphLabelsLocals}
            data={lineGraphDataLocals}
            loading={loading}
          />
        )}
      </ModalGraph>

      <Row className="m-0">
        <Col col={12} lg={7} className="mb-3 p-0 pe-lg-2">
          <BusinessMetricTable
            onGraph={() => setShowGraph((val) => !val)}
            business={business}
            filter={setCurrentBusiness}
            currentBusiness={currentBusiness}
            loading={loading}
          />
        </Col>
        <Col col={12} lg={5} className="mb-3 p-0 ps-lg-2">
          {currentBusiness.ORGANIZACION ===
            defaultCurrentBusiness.ORGANIZACION &&
            !currentManager && (
              <LineGraph
                title="Gráfico Métricas Operacionales"
                labels={lineGraphLabelsTotal}
                data={lineGraphDataTotal}
                loading={loading}
              />
            )}
          {currentBusiness.ORGANIZACION !==
            defaultCurrentBusiness.ORGANIZACION &&
            !currentManager && (
              <LineGraph
                title={"Gráficos | " + currentBusiness.ORGANIZACION}
                labels={lineGraphLabelsManagers}
                data={lineGraphDataManagers}
                loading={loading}
              />
            )}
          {!!currentManager && (
            <LineGraph
              title={`Gráficos | ${currentManager?.GTE_MERC}`}
              labels={lineGraphLabelsLocals}
              data={lineGraphDataLocals}
              loading={loading}
            />
          )}
        </Col>
      </Row>

      <Nav
        className="d-lg-none smu-tabs"
        variant="tabs"
        activeKey={activeTab}
        onSelect={setActiveTab}
      >
        {tabs.map((tab) => (
          <Nav.Item key={tab.id}>
            <Nav.Link eventKey={tab.id}>{tab.title}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      <Row className="m-0">
        <Col col={12} lg={7} className={`mb-3 p-0 pe-lg-2 ${tabAll}`}>
          <ManagerMetricTable
            businessName={currentBusiness?.ORGANIZACION}
            onGraph={() => setShowGraph((val) => !val)}
            managers={handleManagers}
            filter={filterByManager}
            manager={currentManager}
            showButtonGraph={showSeeGraphInTable}
            loading={loading}
          />
        </Col>
        <Col col={12} lg={5} className={`mb-3 p-0 ps-lg-2 ${tabFilter}`}>
          <LocalMetricTable
            businessName={currentBusiness?.ORGANIZACION}
            onGraph={() => setShowGraph((val) => !val)}
            managers={handleManagers}
            filter={filterByManager}
            locals={localsByManager}
            manager={currentManager}
            showButtonGraph={showSeeGraphInTable}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default OperationalMetrics;
