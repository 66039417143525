import React, { useContext } from "react";
import { StoreContext } from "../../../store/StoreContext";

const TotalSalesSummary = ({ className }) => {
  const { viewTitle } = useContext(StoreContext);
  
  return (
    <div className={`d-flex h-100 pe-lg-4 ${className || ""}`}>
      <span className="smu-badge smu-bg-orange my-auto" />
      <div className="ps-2 pe-4 me-lg-5 w-100">
        <div className="smu-text-ligth" style={{ fontSize: 14 }}>
          Última Actualización
        </div>
        <div className="fs-6 fs-lg-4 smu-text-darker text-truncate">
          {viewTitle}
        </div>
      </div>
    </div>
  );
};

export default TotalSalesSummary;
