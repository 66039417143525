import React from "react";
import DataTable from "react-data-table-component";
import SmuLoading from "../smu-loading/SmuLoading";
import SmuPaginator from "../smu-paginator/SmuPaginator";

const paginationComponentOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos",
};

const SmuTable = ({ columns, data, loading, className }) => {
  return (
    <div className={`table-responsive smu-table_ ${className || ""}`}>
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        paginationComponent={SmuPaginator}
        paginationPerPage={10}
        progressPending={!!loading}
        progressComponent={<SmuLoading />}
        noDataComponent="Sin datos para mostrar"
      />
    </div>
  );
};

export default SmuTable;
