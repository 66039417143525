import "./App.scss";
import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Access from "./views/Access";
import MainLayout from "./views/layouts/MainLayout";
import { StoreContext } from "./store/StoreContext";
import AdminLayout from "./views/layouts/AdminLayout";
import { useContext, useEffect } from "react";
import { SMU_TOKEN, SMU_USER } from "./constants";
import NotFound from "./views/NotFound";
import RequireAuth from "./views/RequireAuth";
import Sales from "./views/protected-page/general/Sales";
import OperationalMetrics from "./views/protected-page/general/OperationalMetrics";
import PromotionalMonitor from "./views/protected-page/general/PromotionalMonitor";
import Users from "./views/protected-page/admin/Users";
import jwtDecode from "jwt-decode";
import useAuth from "./hooks/useAuth"

const App = () => {

  const { permissions, isAdmin } = useContext(StoreContext);
  
  const { validate } = useAuth();

  useEffect(() => {
    validate({});
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/access" replace />} />
      <Route path="/access" element={<Access />} />

      <Route
        path="/portal-sales/"
        element={
          <RequireAuth>
            <MainLayout />
          </RequireAuth>
        }
      >

        {permissions?.venta_online && (
          <Route index path="sales" element={<Sales />} />
        )}
        {permissions?.metricas_operactionales && (
          <Route path="operational-metrics" element={<OperationalMetrics />} />
        )}
        {permissions?.monitor_promocional && (
          <Route path="promotional-monitor" element={<PromotionalMonitor />} />
        )}
      </Route>

   
      <Route
        path="/portal-sales-admin/"
        element={
          <RequireAuth>
            <AdminLayout />
          </RequireAuth>
        }
      >
        {!!isAdmin && <Route index path="users" element={<Users />} />}
      </Route>
        
      <Route path="*" element={<NotFound />} /> <Route/>

    </Routes>
  );
};

export default App;
