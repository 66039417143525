const optionsBar = {
  plugins: {
    legend: {
      position: "bottom",
      align: "center",
      padding: "10px",
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    xAxes: {
      stacked: true,
    },
    yAxes: {
      stacked: true,
      grid: {
        drawBorder: false,
        color: "#00000000",
        display: false,
      },
    },
  },
};

const optionsLine = {
  plugins: {
    legend: {
      position: "bottom",
      align: "center",
      padding: "10px",
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    yAxes: {
      ticks: {
        stepSize: 10,
      },
      gridLines: {
        drawTicks: false,
      },
    },

    xAxes: {
      gridLines: {
        display: false,
      },
    },
  },
};

export { optionsBar, optionsLine };
