import React from "react";
import { Modal } from "react-bootstrap";
import { IconArrowLeft } from "../../icons/Icon";
import "../../assets/styles/smu-modal.scss";
import BtnClose from "../btn-close/BtnClose";

const SmuModal = ({
  show,
  onHide,
  size,
  _className,
  children,
  contentTitle,
}) => {
  return (
    <Modal
      size={size || "lg"}
      centered
      show={show}
      onHide={onHide}
      className={`smu-modal px-2 px-lg-0 ${_className || ""}`}
    >
      <div className="header p-4 pt-5">
        {contentTitle}

        <button
          type="button"
          className="smu-generic-button ms-auto px-4 py-1"
          onClick={() => onHide()}
        >
          <IconArrowLeft color="#E31C19" />
          <span className="ms-3">Volver</span>
        </button>

        <BtnClose control={() => onHide(false)} />
      </div>
      <Modal.Body className="p-4">{children}</Modal.Body>
    </Modal>
  );
};

export default SmuModal;
