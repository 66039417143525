import React from "react";

const IconStar = ({ color = "#e31c19" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.752"
      height="20.744"
      viewBox="0 0 20.752 20.744"
    >
      <g
        id="UI_icon_promotion_light"
        data-name="UI icon/promotion/light"
        transform="translate(-1.624 -1.628)"
      >
        <path
          id="Star_4_Stroke_"
          data-name="Star 4 (Stroke)"
          d="M10.373,20.745a3,3,0,0,1-1.946-.717L7.155,18.944a1,1,0,0,0-.569-.236L4.92,18.576a2.987,2.987,0,0,1-2.751-2.752l-.133-1.665A.988.988,0,0,0,1.8,13.59L.716,12.318a2.989,2.989,0,0,1,0-3.892L1.8,7.155a.988.988,0,0,0,.236-.569L2.169,4.92A2.986,2.986,0,0,1,4.92,2.169l1.666-.133A.988.988,0,0,0,7.155,1.8L8.427.716a3,3,0,0,1,3.892,0L13.59,1.8a.988.988,0,0,0,.569.236l1.665.133A2.987,2.987,0,0,1,18.576,4.92l.132,1.666a1,1,0,0,0,.236.569l1.084,1.272a2.987,2.987,0,0,1,0,3.892L18.944,13.59a1,1,0,0,0-.236.569l-.132,1.665a2.987,2.987,0,0,1-2.752,2.752l-1.665.132a1,1,0,0,0-.569.236l-1.272,1.084A3,3,0,0,1,10.373,20.745ZM10.372,2a1,1,0,0,0-.648.239L8.452,3.323a2.983,2.983,0,0,1-1.707.707L5.08,4.163a.994.994,0,0,0-.917.917L4.029,6.745a2.983,2.983,0,0,1-.706,1.707L2.239,9.724a1,1,0,0,0,0,1.3l1.084,1.272A2.983,2.983,0,0,1,4.029,14l.133,1.665a1,1,0,0,0,.917.918l1.665.132a2.983,2.983,0,0,1,1.707.707l1.272,1.084a1,1,0,0,0,1.3,0l1.272-1.084A2.984,2.984,0,0,1,14,16.715l1.665-.132a1,1,0,0,0,.918-.918L16.715,14a2.984,2.984,0,0,1,.707-1.707l1.084-1.272a1,1,0,0,0,0-1.3L17.422,8.452a2.984,2.984,0,0,1-.707-1.707L16.583,5.08a1,1,0,0,0-.918-.917L14,4.029a2.984,2.984,0,0,1-1.707-.707L11.021,2.239A1,1,0,0,0,10.372,2Z"
          transform="translate(1.628 1.628)"
          fill={color}
        />
        <path
          id="Ellipse_201"
          data-name="Ellipse 201"
          d="M3,1.5A1.5,1.5,0,1,1,1.5,0,1.5,1.5,0,0,1,3,1.5Z"
          transform="translate(8 8)"
          fill={color}
        />
        <path
          id="Ellipse_202"
          data-name="Ellipse 202"
          d="M3,1.5A1.5,1.5,0,1,1,1.5,0,1.5,1.5,0,0,1,3,1.5Z"
          transform="translate(13 13)"
          fill={color}
        />
        <path
          id="Vector_617_Stroke_"
          data-name="Vector 617 (Stroke)"
          d="M6.64.232A1,1,0,0,1,6.768,1.64l-5,6A1,1,0,1,1,.232,6.36l5-6A1,1,0,0,1,6.64.232Z"
          transform="translate(8.5 8)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default IconStar;
