import React, { useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IconArrowsRightLeft } from "../../icons/Icon";
import graphLineIcon from "../../assets/images/graph-line-icon.png";
import Select from "../select/Select";
import SmuPaginator from "../../util/smu-paginator/SmuPaginator";
import { useSortBy, useTable } from "react-table/dist/react-table.development";
import { pascalCase, numberFormatter, priceFormatter } from "../../helpers";
import SmuTable from "../../util/smu-table/SmuTable";

const AccumulatedIndicatorsTable = ({
  onSwith,
  sales,
  business,
  sections,
  lines,
  businessId,
  setBusinessId,
  sectionId,
  setSectionId,
  lineId,
  setLineId,
  loading,
}) => {
  const columns = [
    {
      name: "Categoría",
      selector: (row) => row.descCategoria,
      sortable: true,
      Cell: (row) => (
        <div className="cell">{pascalCase(row.descCategoria)}</div>
      ),
    },
    {
      name: "Stock Tot. Un.",
      selector: (row) => row.stockDiarioDisponible,
      sortable: true,
      Cell: (row) => (
        <div className="cell">
          {numberFormatter.format(row.stockDiarioDisponible)}
        </div>
      ),
    },
    {
      name: "Un. Vendidas",
      selector: (row) => row.unidadesVendidasAcum,
      sortable: true,
      Cell: (row) => (
        <div className="cell">
          {numberFormatter.format(row.unidadesVendidasAcum)}
        </div>
      ),
    },
    {
      name: "Tot. Ventas",
      selector: (row) => row.ventaTotalAcum,
      sortable: true,
      Cell: (row) => (
        <div
          className="cell"
          style={{
            color: row.ventaTotalAcum >= 0 ? "#91C67F" : "e31c19",
          }}
        >
          {priceFormatter.format(row.ventaTotalAcum)}
        </div>
      ),
    },
    {
      name: "% Parti",
      selector: (row) => row.poncentPartiAcum,
      sortable: true,
      Cell: (row) => (
        <div className="cell" style={{ whiteSpace: "nowrap" }}>
          {row.poncentPartiAcum}
        </div>
      ),
    },
    {
      name: "Cant. Ticket",
      selector: (row) => row.cantidadDeTicketAcum,
      sortable: true,
      Cell: (row) => (
        <div className="cell">
          {numberFormatter.format(row.cantidadDeTicketAcum)}
        </div>
      ),
    },
  ];

  return (
    <Card className="smu-card">
      <Card.Header className="border-0 bg-transparent">
        <Row className="d-flex align-items-center">
          <Col col={12} xs={9} lg={4} className="mb-4 mb-lg-0">
            <div className="d-flex align-items-center">
              <img src={graphLineIcon} alt="ICON" />
              <div className="fs-5 ms-3 smu-text-dark">
                Indicadores Acumulados
              </div>
            </div>
          </Col>
          <div className="-flex d-lg-none ms-auto w-auto mb-4">
            <button
              type="button"
              className="btn-swith ms-auto"
              style={{ backgroundColor: "#FC5E5C" }}
              onClick={() => onSwith((val) => !val)}
            >
              <IconArrowsRightLeft color="#fff" />
            </button>
          </div>

          <Col col={12} lg={6} className="me-3">
            <Row className="d-flex">
              <Col>
                <Select
                  className="indicators-select mx-3"
                  defaultOption="Negocio"
                  options={business}
                  value={businessId}
                  onChange={({ target }) => setBusinessId(target.value)}
                />
              </Col>
              <Col>
                <Select
                  className="indicators-select mx-3"
                  defaultOption="Sección"
                  options={sections}
                  value={sectionId}
                  onChange={({ target }) => setSectionId(target.value)}
                />
              </Col>
              <Col>
                <Select
                  className="indicators-select mx-3"
                  defaultOption="Línea"
                  options={lines}
                  value={lineId}
                  onChange={({ target }) => setLineId(target.value)}
                />
              </Col>
            </Row>
          </Col>

          <div className="d-none d-lg-flex w-auto ms-auto">
            <button
              type="button"
              className="btn-swith ms-auto"
              style={{ backgroundColor: "#FC5E5C" }}
              onClick={() => onSwith((val) => !val)}
            >
              <IconArrowsRightLeft color="#fff" />
            </button>
          </div>
        </Row>
      </Card.Header>
      <Card.Body>
        <SmuTable
          columns={columns}
          data={sales}
          className="red-table"
          loading={loading}
        />
      </Card.Body>
    </Card>
  );
};

export default AccumulatedIndicatorsTable;
