import React from "react";

const IconArrowDown1 = ({ color = "#e31c19" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="12"
      viewBox="0 0 15 18.086"
    >
      <g
        id="UI_icon_arrow_forward_filled"
        data-name="UI icon/arrow_forward/filled"
        transform="translate(19 -4.2) rotate(90)"
      >
        <path
          id="Union"
          d="M10.293,13.293a1,1,0,0,0,1.414,1.414L17.5,8.914a2,2,0,0,0,0-2.828L11.707.293a1,1,0,0,0-1.414,1.414L15.086,6.5H1a1,1,0,1,0,0,2H15.086Z"
          transform="translate(3 4.5)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default IconArrowDown1;
