import React from "react";
import { Table, Row, Col, Form } from "react-bootstrap";
import { IconOptions } from "../../icons/Icon";
import SmuLoading from "../smu-loading/SmuLoading";
import CellTh from "./parts/CellTh";
import CellItem from "./parts/CellItem";

const SmuTable = ({
  headers,
  children,
  _className,
  headerStyles,
  hiddenSelectedAll,
  selectedAll,
  textSelectedAll,
  hiddenFilter,
  hiddenOptions,
  loading,
  order,
  noOrder,
  search,
  setSearch,
}) => {
  const hiddenAll = hiddenSelectedAll && hiddenFilter && hiddenOptions;

  const handleOrder = (indexCol, direction) => {
    if (!order) return;

    order(indexCol, direction);
  };

  return (
    <>
      <Row className={`mb-4 ${hiddenAll ? "d-none" : ""}`}>
        <Col col={12} className="col-4 d-flex align-items-center">
          {!hiddenSelectedAll && (
            <Form.Check
              type="checkbox"
              id="selected-all"
              onChange={(event) => selectedAll(event)}
            >
              <Form.Check.Input type="checkbox" />
              <Form.Check.Label className="ms-3">
                {textSelectedAll || "Seleccionar todo"}
              </Form.Check.Label>
            </Form.Check>
          )}
        </Col>
        <Col col={12} className="col-4 d-flex align-items-center">
          {!hiddenFilter && (
            <Form className="smu-form w-100">
              <Form.Control
                type="search"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Filtrar Usuario"
              />
            </Form>
          )}
        </Col>
        <Col col={12} className="col-4 d-flex">
          {!hiddenOptions && (
            <button
              type="button"
              className="smu-button-table-options ms-auto my-auto"
            >
              <IconOptions color="#6F767E" />
            </button>
          )}
        </Col>
      </Row>
      <Table className={"smu-table " + _className || ""} responsive>
        <thead>
          <tr style={Boolean(headerStyles) ? headerStyles : {}}>
            {headers.map((item, i) => (
              <th key={i} style={Boolean(headerStyles) ? headerStyles : {}}>
                <CellTh
                  onClick={(_order) => handleOrder(i, _order)}
                  color={headerStyles?.color || null}
                  noOrder={noOrder}
                >
                  {item.title}
                </CellTh>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            children
          ) : (
            <tr>
              <CellItem colSpan={headers.length} _className="d-flex">
                <SmuLoading />
              </CellItem>
            </tr>
          )}
        </tbody>
      </Table>

      {/* <SmuPaginator totalRecords={totalRecords} pageLimit={2} /> */}
    </>
  );
};

export default SmuTable;
