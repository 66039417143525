import React from "react";

const IconArrowsRightLeft = ({ color = "#e31c19" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.715"
      height="20.856"
      viewBox="0 0 15.715 20.856"
    >
      <g
        id="UI_icon_arrow_up_down_light"
        data-name="UI icon/arrow_up_down/light"
        transform="translate(15.715) rotate(90)"
      >
        <path
          id="Union"
          d="M1.78,5.144a1.1,1.1,0,0,1-1.475,0,.926.926,0,0,1,0-1.374L3.739.569a2.195,2.195,0,0,1,2.949,0l3.434,3.2a.926.926,0,0,1,0,1.374,1.1,1.1,0,0,1-1.475,0L6.257,2.915V14.66a1.009,1.009,0,0,1-1.043.972,1.009,1.009,0,0,1-1.043-.972V2.915Z"
          transform="translate(0 0)"
          fill={color}
        />
        <path
          id="Union-2"
          data-name="Union"
          d="M6.257.972A1.009,1.009,0,0,0,5.214,0,1.009,1.009,0,0,0,4.171.972V12.717L1.78,10.488a1.1,1.1,0,0,0-1.475,0,.926.926,0,0,0,0,1.374l3.434,3.2a2.2,2.2,0,0,0,2.949,0l3.434-3.2a.926.926,0,0,0,0-1.374,1.1,1.1,0,0,0-1.475,0L6.257,12.717Z"
          transform="translate(10.428 0.083)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default IconArrowsRightLeft;
