import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import SmuTable from "../../util/smu-tables/SmuTable";
import RowItem from "../../util/smu-tables/parts/RowItem";
import CellItem from "../../util/smu-tables/parts/CellItem";
import { numberFormatter, priceFormatter } from "../../helpers";

const headers = [
  { title: "General" },
  { title: "Diario" },
  { title: "Acumulado" },
];
const headerStyles = {
  color: "#fff",
  backgroundColor: "#E31C19",
};

const SummaryTable = ({ summary, loading }) => {
  return (
    <Card className="smu-card">
      <Card.Header className="border-0 bg-transparent">
        <Row>
          <Col col={6}>
            <div className="d-flex">
              <div className="smu-badge smu-bg-blue-light" />
              <div className="fs-5 ms-3 smu-text-dark">Monitor Promocional</div>
            </div>
          </Col>
          <Col col={6} className="d-flex">
            <div className="fs-5 ms-auto smu-text-dark">
              {summary?.descPromo && "|"} {summary?.descPromo}
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <SmuTable
          headers={headers}
          headerStyles={headerStyles}
          _className="bordered"
          hiddenSelectedAll={true}
          hiddenFilter={true}
          hiddenOptions={true}
          loading={loading}
          noOrder={true}
        >
          <RowItem>
            <CellItem _className="cell">
              Total Venta {summary?.descOrganizacion}
            </CellItem>
            <CellItem _className="cell">
              {
                priceFormatter.format(summary?.ventaTotalDiario || 0)
              }
              </CellItem>
            <CellItem _className="cell">
              {
                priceFormatter.format(summary?.ventaTotalAcum || 0)
              }
              </CellItem>
          </RowItem>
          <RowItem>
            <CellItem _className="cell">Unidades Vendidas</CellItem>
            <CellItem _className="cell">
              {numberFormatter.format(summary?.unidadesVendidasApoteosicoDiario || 0)}
            </CellItem>
            <CellItem _className="cell">
              {numberFormatter.format(summary?.unidadesVendidasApoteosicoAcum || 0)}
            </CellItem>
          </RowItem>
          <RowItem>
            <CellItem _className="cell">Venta Total Apoteosicos</CellItem>
            <CellItem _className="cell">
              {priceFormatter.format(summary?.ventaTotalDiarioApoteosico || 0)}
            </CellItem>
            <CellItem _className="cell">
              {priceFormatter.format(summary?.ventaTotalApoteosicoAcum || 0)}
            </CellItem>
          </RowItem>
          <RowItem>
            <CellItem _className="cell">Cantidad de Tickets</CellItem>
            <CellItem _className="cell">{numberFormatter.format(summary?.cantTicketHoy || 0)}</CellItem>
            <CellItem _className="cell">{numberFormatter.format(summary?.cantTicketAcum || 0)}</CellItem>
          </RowItem>
          <RowItem>
            <CellItem _className="cell">% Participación</CellItem>
            <CellItem _className="cell">{summary?.poncentParti} %</CellItem>
            <CellItem _className="cell">{summary?.poncentPartiAcum} %</CellItem>
          </RowItem>
          <RowItem>
            <CellItem _className="cell">Stock Unidades</CellItem>
            <CellItem _className="cell">
              {numberFormatter.format(summary?.stockDiarioDisponible || 0)}
            </CellItem>
            <CellItem _className="cell">-</CellItem>
          </RowItem>
        </SmuTable>
      </Card.Body>
    </Card>
  );
};

export default SummaryTable;
