import React, { useEffect, useState } from "react";
import SmuButton from "../../util/smu-button/SmuButton";
import { Card, Col, Form, Row } from "react-bootstrap";
import { IconPencilEdit, IconTrash } from "../../icons/Icon";
import SmuBadge from "../../util/smu-badge/SmuBadge";
import SmuUserIcon from "../../util/smu-user-icon/SmuUserIcon";
import moment from "moment";
import { prepareText } from "../../helpers";
import EditUser from "../edit-user/EditUser";
import { deleteUser, getUsers, signUp } from "../../api/users";
import { toast } from "../../helpers/smu-alerts/smu-lerts";
import loadingGif from "../../assets/images/loading.gif";
import SmuTable from "../../util/smu-table/SmuTable";
import { Search } from "../search/Search";
import _ from "lodash";
import Swal from "sweetalert2";

const UsersTable = () => {
  const formatDate = (date) => moment(date).format("DD-MM-YYYY HH:mm");

  const [search, setSearch] = useState("");
  const [username, setUsername] = useState("");
  const [profileId, setProfileId] = useState("");
  const [creating, setCreating] = useState(false);
  const [handleUsers, setHandleUsers] = useState([]);
  const [userToEdit, setUserToEdit] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(null);
  const [users, setUsers] = useState([]);

  const columns = [
    {
      selector: (row) => null,
      cell: (row) => (
        <div className="cell d-flex align-items-center">
          <SmuUserIcon color={"red"} />
        </div>
      ),
      name: "",
      maxWidth: "30px",
    },
    {
      selector: (row) => row.name,
      cell: (row) => (
        <div className="cell" style={{ wordWrap: "break-word" }}>
          {row.name || row.username}
        </div>
      ),
      name: "Nombre de Usuario",
      sortable: true,
    },
    {
      selector: (row) => row.username,
      cell: (row) => (
        <div className="cell text-truncate" style={{ wordWrap: "break-word" }}>
          {row.username}
        </div>
      ),
      name: "LogIn",
      sortable: true,
    },
    {
      selector: (row) => row.admin,
      cell: (row) => (
        <div className="cell">{(row.admin == true || row.admin == 'true') ? "Admin" : "Usuario"}</div>
      ),
      name: "Perfil",
      sortable: true,
    },
    {
      selector: (row) => row.timestamp,
      cell: (row) => <div className="cell">{formatDate(row.timestamp)}</div>,
      name: "Fecha Validación",
      sortable: true,
    },
    {
      selector: (row) => row.status,
      cell: (row) => (
        <div className="cell d-flex align-items-center">
          <Form className="ms-5">
            <Form.Check
              type="switch"
              checked={row.status === "1"}
              onChange={(event) => choseStatus(event, row)}
              disabled={loadingStatus === row.username}
            />
          </Form>
          {loadingStatus === row.username && (
            <img
              src={loadingGif}
              alt="Cargando"
              className="m-auto"
              width={15}
              height={15}
            />
          )}
        </div>
      ),
      name: "Habilitar Usuario",
      sortable: true,
    },
    {
      selector: (row) => null,
      cell: (row) => (
        <div className="cell d-flex align-items-center">
          <SmuButton _className="ms-3" control={() => setUserToEdit(row)}>
            <IconPencilEdit color="#33383F" />
          </SmuButton>
          <SmuButton
            _className="ms-3"
            control={() => handleDelete(row.username)}
          >
            <IconTrash color="#6F767E" />
          </SmuButton>
        </div>
      ),
      name: "",
    },
  ];

  const handleGetUsers = () => {
    getUsers().then(({ data }) => {
      setUsers(data.users);
    });
  };

  useEffect(() => {
    let _users = users;

    if (!!search) {
      _users = _users.filter((_user) => {
        const _search = prepareText(search);
        const name = prepareText(_user.name);
        const status = prepareText(_user.status);
        const datetime = prepareText(_user.timestamp);
        const username = prepareText(_user.username);
        return (
          name.includes(_search) ||
          status.includes(_search) ||
          datetime.includes(_search) ||
          username.includes(_search)
        );
      });
    }

    _users = _.orderBy(_users, ["timestamp"], ["desc"]);
    setHandleUsers(_users);
  }, [users, search]);

  useEffect(() => {
    handleGetUsers();
  }, []);

  const provideAccess = (event) => {
    event.preventDefault();
    if (!username)
      return toast({ icon: "warning", title: "Debe ingresar username." });
    if (!profileId)
      return toast({ icon: "warning", title: "Debe seleccionar un perfil." });

    setCreating(true);
    const _user = users.find((u) => {
      return u.username.toUpperCase() === username.toUpperCase();
    });
    if (!!_user) {
      setCreating(false);
      return toast({
        icon: "warning",
        title: "¡El nombre de usuario ya existe!",
      });
    }

    const permission = {
      venta_online: false,
      metricas_operactionales: false,
      monitor_promocional: false,
    };

    const newUser = new FormData();
    newUser.append("username", username);
    newUser.append("name", username);
    newUser.append("status", 1);
    newUser.append("admin", profileId == 1);
    newUser.append("permission", JSON.stringify(permission));

    signUp(newUser)
      .then(({ error }) => {
        if (!!error) throw new Error(error.message);

        handleGetUsers();
        toast({ icon: "success", title: "¡Usuario creado con éxito!" });
      })
      .catch((err) => {
        toast({ icon: "error", title: err });
      })
      .finally(() => {
        setUsername("");
        setProfileId("");
        setCreating(false);
      });
  };

  const editUser = (_newUser, file) => {
    if (!_newUser?.name) return;

    const newUser = new FormData();

    newUser.append("username", _newUser.username);
    newUser.append("name", _newUser.name);
    newUser.append("password", _newUser.password);
    newUser.append("user_type", _newUser.userType);
    newUser.append("status", _newUser.status);
    newUser.append("admin", _newUser.admin);
    newUser.append("avatar", _newUser.avatar);
    newUser.append("permission", JSON.stringify(_newUser.permission));
    if (!!file) {
      newUser.append("avatar", file, file.name);
      newUser.append("is_public", true);
    }

    return signUp(newUser)
      .then(({ error }) => {
        if (!!error) throw new Error(error.message);

        handleGetUsers();
        toast({ icon: "success", title: "¡Usuario actualizado!" });
      })
      .catch((err) => {
        toast({ icon: "error", title: err });
      });
  };

  const choseStatus = (event, _user) => {
    setLoadingStatus(_user.username);
    const newUser = {
      ..._user,
      status: event.target.checked ? "1" : "0",
    };
    editUser(newUser).finally(() => setLoadingStatus(null));
  };

  const handleDelete = (_username) => {
    Swal.fire({
      title: "¿Deseas eliminar este usuario?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#E31C19",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (!result.isConfirmed) return;

      deleteUser(_username)
        .then(({ error }) => {
          if (!!error) throw new Error(error?.message);
          toast({ icon: "success", title: "Usuario eliminado." });
        })
        .catch((err) => {
          toast({ icon: "error", title: err });
        })
        .finally(() => {
          handleGetUsers();
        });
    });
  };

  return (
    <>
      <EditUser
        user={userToEdit}
        onHide={() => setUserToEdit(null)}
        editUser={editUser}
      />

      <Card className="smu-card">
        <Card.Header className="d-flex flex-column py-4 border-0">
          <div className="d-flex align-items-center me-auto">
            <SmuBadge color="#E31C19" />
            <span className="fs-5 ms-3 me-auto">Administrar Usuarios</span>
          </div>

          <div className="mt-4 mb-0 px-5 mx-5 text-muted">
            Busca y perfila usuarios desde el ”Buscador de usuarios” para darles
            acceso al portal. También puedes filtrar los usuarios que ya están
            agregados y administrarlos desde el “Panel de usuarios agregados”.
          </div>

          <Form
            className="smu-form px-5 mx-5 my-5"
            onSubmit={(event) => provideAccess(event)}
          >
            <Row>
              <Col col={12} className="col-5">
                <Form.Control
                  type="text"
                  placeholder="Buscar usuario"
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                  required
                />
              </Col>
              <Col col={12} className="col-5">
                <Form.Select
                  value={profileId}
                  onChange={(event) => setProfileId(event.target.value)}
                >
                  <option value="">Seleccionar Perfil*</option>
                  <option value="1">Admin</option>
                  <option value="2">Usuarios</option>
                </Form.Select>
              </Col>
              <Col col={12} className="col-2">
                {!creating ? (
                  <button
                    type="submit"
                    className="smu-generic-button text-truncate h-100"
                    disabled={!username}
                  >
                    Dar Acceso
                  </button>
                ) : (
                  <div className="smu-generic-button h-100 d-flex">
                    <img
                      src={loadingGif}
                      alt="Cargando"
                      className="m-auto"
                      width={28}
                      height={28}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </Card.Header>
        <Card.Header className="d-flex py-4 border-0">
          <div className="d-flex align-items-center me-auto">
            <SmuBadge color="#E31C19" />
            <span className="fs-5 ms-3 me-auto">
              Panel de usuarios agregados
            </span>
          </div>
          <Search
            vslue={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </Card.Header>
        <Card.Body className="pt-0">
          <SmuTable columns={columns} data={handleUsers} />
        </Card.Body>
      </Card>
    </>
  );
};

export default UsersTable;
