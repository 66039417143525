import React from "react";

const IconWarning = ({ color = "#e31c19" }) => {
  return (
    <svg
      id="UI_icon_warning_light"
      data-name="UI icon/warning/light"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_51_Stroke_"
        data-name="Path 51 (Stroke)"
        d="M1,0A1,1,0,0,0,2-1V-6A1,1,0,0,0,1-7,1,1,0,0,0,0-6v5A1,1,0,0,0,1,0Z"
        transform="translate(11 15)"
        fill={color}
      />
      <path
        id="Polygon_1_Stroke_"
        data-name="Polygon 1 (Stroke)"
        d="M16.614,17.941H3A3,3,0,0,1,.4,13.456L7.2,1.515a3,3,0,0,1,5.213,0l6.8,11.941a3,3,0,0,1-2.606,4.485ZM9.809,2a.989.989,0,0,0-.869.5l-6.8,11.941A1,1,0,0,0,3,15.941h13.61a1,1,0,0,0,.869-1.495L10.679,2.5A.99.99,0,0,0,9.809,2Z"
        transform="translate(2.191 3.059)"
        fill={color}
      />
      <path
        id="Ellipse_135"
        data-name="Ellipse 135"
        d="M2,1A1,1,0,1,1,1,0,1,1,0,0,1,2,1Z"
        transform="translate(11 16)"
        fill={color}
      />
    </svg>
  );
};

export default IconWarning;
