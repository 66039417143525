import React, { useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IconShoppingBag } from "../../icons/Icon";
import itemUp from "../../assets/images/icon-up.png";
import itemDown from "../../assets/images/icon-down.png";
import { logos } from "../../constants/logos";
import SmuTable from "../../util/smu-table/SmuTable";

const OperationalMetricTable = ({
  onGraph,
  business,
  currentBusiness,
  filter,
  loading,
}) => {
  const handleFilter = (organization) => {
    const bus = business.find((b) => b.ORGANIZACION === organization);
    if (!bus) return;
    filter(bus);
  };

  const columns = useMemo(
    () => [
      {
        name: "Negocio",
        selector: (row) => row.ORGANIZACION,
        cell: (row) => (
          <div className="cell d-flex align-items-center">
            <input
              className="form-check-input"
              type="radio"
              name="business-metric"
              id={"sale" + row.ORGANIZACION}
              onChange={() => handleFilter(row.ORGANIZACION)}
              checked={row.ORGANIZACION === currentBusiness?.ORGANIZACION}
            />
            <label
              className="form-check-label"
              htmlFor={"sale" + row.ORGANIZACION}
            >
              <img
                src={logos[row.ORGANIZACION.toLowerCase()]}
                alt={row.ORGANIZACION}
                className="ms-4"
                {...(row.ORGANIZACION === "TOTAL" ? { width: 55 } : {})}
              />
            </label>
          </div>
        ),
      },
      {
        name: "DOH",
        selector: (row) => row.DHO,
        cell: (row) => (
          <div
            className="cell d-flex align-items-center"
            style={{ whiteSpace: "nowrap" }}
          >
            <img
              src={Number(row.DHO) >= 0 ? itemUp : itemDown}
              alt={row.DHO}
              className="me-3"
            />
            <div
              style={{
                color: Number(row.DHO) >= 0 ? "#83bf6e" : "#e31c19",
              }}
            >
              {row.DHO}
            </div>
          </div>
        ),
      },
      {
        name: "InStock",
        selector: (row) => row.INSTOCK,
        cell: (row) => (
          <div className="cell d-flex align-items-center">{row.INSTOCK}</div>
        ),
      },
      {
        name: "NSG",
        selector: (row) => row.NSG,
        cell: (row) => (
          <div className="cell d-flex align-items-center">{row.NSG}</div>
        ),
      },
    ],
    [currentBusiness]
  );

  return (
    <Card className="smu-card">
      <Card.Header className="border-0 bg-transparent">
        <Row>
          <Col col={6}>
            <div className="d-flex">
              <div className="smu-badge smu-bg-blue-light" />
              <div className="fs-5 ms-3 smu-text-dark">
                Métricas Operacionales
              </div>
            </div>
          </Col>
          <Col col={6} className="ms-auto d-flex d-lg-none">
            <button
              type="button"
              className="ms-auto px-3 btn-see-graph"
              onClick={() => onGraph()}
            >
              <IconShoppingBag color="#E31C19" />{" "}
              <span className="ms-2">Ver Gráfico</span>
            </button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <SmuTable columns={columns} data={business} loading={loading} />
      </Card.Body>
    </Card>
  );
};

export default OperationalMetricTable;
