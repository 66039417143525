import React from "react";

const IconConfig = ({ color = "#e31c19" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.21"
      height="22"
      viewBox="0 0 20.21 22"
    >
      <g
        id="Grupo_22348"
        data-name="Grupo 22348"
        transform="translate(-1.894 -1)"
      >
        <path
          id="Oval_Stroke_"
          data-name="Oval (Stroke)"
          d="M4,8A4,4,0,1,1,8,4,4,4,0,0,1,4,8ZM4,2A2,2,0,1,0,6,4,2,2,0,0,0,4,2Z"
          transform="translate(8 8)"
          fill={color}
        />
        <path
          id="Union_Stroke_"
          data-name="Union (Stroke)"
          d="M10.726,22H9.484a2,2,0,0,1-1.9-1.367L7.2,19.47a2.313,2.313,0,0,0-1.1-1.256c-.09-.051-.164-.094-.233-.135a2.437,2.437,0,0,0-1.244-.367,1.974,1.974,0,0,0-.4.04L3.022,18a2,2,0,0,1-2.133-.959L.268,15.962A2,2,0,0,1,.5,13.635l.815-.919a2.308,2.308,0,0,0,.538-1.58c0-.09,0-.181,0-.271a2.309,2.309,0,0,0-.538-1.581L.5,8.365A2,2,0,0,1,.268,6.038L.889,4.963A2,2,0,0,1,3.022,4l1.2.246a1.981,1.981,0,0,0,.395.039,2.436,2.436,0,0,0,1.246-.367c.076-.045.154-.09.233-.134A2.317,2.317,0,0,0,7.2,2.53l.387-1.163A2,2,0,0,1,9.484,0h1.242a2,2,0,0,1,1.9,1.367L13.01,2.53a2.317,2.317,0,0,0,1.1,1.257c.079.044.158.089.233.134a2.44,2.44,0,0,0,1.246.367,1.98,1.98,0,0,0,.395-.039L17.187,4a2,2,0,0,1,2.133.959l.621,1.076a2,2,0,0,1-.236,2.327l-.814.919a2.312,2.312,0,0,0-.538,1.581c0,.089,0,.181,0,.271a2.308,2.308,0,0,0,.538,1.58l.814.919a2,2,0,0,1,.236,2.327l-.621,1.076A2,2,0,0,1,17.187,18l-1.2-.246a1.974,1.974,0,0,0-.4-.04,2.437,2.437,0,0,0-1.244.367c-.069.041-.143.084-.233.135a1.193,1.193,0,0,0-.523.378,5.9,5.9,0,0,0-.579.878l-.387,1.163A2,2,0,0,1,10.726,22ZM4.619,15.711a4.458,4.458,0,0,1,2.275.653c.058.035.118.069.176.1A4.346,4.346,0,0,1,9.1,18.837L9.484,20h1.242l.387-1.163a4.346,4.346,0,0,1,2.026-2.372c.058-.033.118-.066.176-.1a4.455,4.455,0,0,1,2.273-.652,3.987,3.987,0,0,1,.8.081l1.2.246.621-1.075-.815-.92A4.335,4.335,0,0,1,16.353,11.1c0-.069,0-.139,0-.208a4.336,4.336,0,0,1,1.041-2.939l.815-.919-.621-1.076-1.2.246a3.992,3.992,0,0,1-.8.081,4.455,4.455,0,0,1-2.273-.652c-.051-.031-.109-.065-.176-.1a4.339,4.339,0,0,1-2.026-2.371L10.726,2H9.484L9.1,3.163A4.339,4.339,0,0,1,7.07,5.534c-.059.033-.118.068-.176.1a4.455,4.455,0,0,1-2.273.652,3.991,3.991,0,0,1-.8-.081l-1.2-.246L2,7.038l.815.919A4.336,4.336,0,0,1,3.857,10.9c0,.069,0,.139,0,.208a4.335,4.335,0,0,1-1.041,2.939L2,14.963l.621,1.075,1.2-.247A3.991,3.991,0,0,1,4.619,15.711Z"
          transform="translate(1.895 1)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default IconConfig;
