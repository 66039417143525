import React from "react";
import { Form } from "react-bootstrap";
import "../../assets/styles/select.scss";
import _ from "lodash";

const Select = ({ _className, defaultOption, options, onChange, value }) => {
  return (
    <Form.Select
      className={`smu-select ms-auto ${_className || ""}`}
      onChange={onChange}
      value={value || ""}
    >
      <option value="">{defaultOption || "Seleccionar"}</option>
      {_.map(options, (opt, i) => (
        <option key={i} value={opt.id}>
          {opt.title}
        </option>
      ))}
    </Form.Select>
  );
};

export default Select;
