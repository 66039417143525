import React, { forwardRef } from "react";
import { Dropdown, Image } from "react-bootstrap";

const SmuProfile = ({ avatar, name, _className, options }) => {
  const Toggle = forwardRef(({ children, onClick }, ref) => {
    return (
      <div
        className="profile d-flex justify-content-end ms-4"
        ref={ref}
        onClick={onClick}
      >
        {children}
      </div>
    );
  });

  return (
    <Dropdown align="end" className={`profile-dropdown ${_className || ""}`}>
      <Dropdown.Toggle as={Toggle}>
        <div className="d-flex justify-content-end ms-auto">
          <div className="profile__name smu-text-ligth fs-6 d-none d-lg-flex">
            {name}
          </div>
          <Image src={avatar} roundedCircle width={48} height={48} />
        </div>
      </Dropdown.Toggle>

      {options.length > 0 && (
        <Dropdown.Menu className="border-0 shadow">
          {options.map((item) => (
            <Dropdown.Item
              key={item.id}
              eventKey={item.id}
              onClick={() => {
                if (item?.action) item.action();
              }}
            >
              {item.Icon} <span className="ms-3">{item.title}</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default SmuProfile;
