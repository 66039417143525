import React, { useRef, useState, useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { IconArrowUp } from "../../icons/Icon";
import SideBar from "../../components/sidebar/SideBar";
import Topbar from "../../components/topbar/general/Topbar";
import SmuPreload from "../../util/smu-preload/SmuPreload";
import { StoreContext } from "../../store/StoreContext";
import useStart from "../../hooks/useStart";

const MainLayout = () => {
  const scrollRef = useRef();
  const { showPreload, date } = useContext(StoreContext);
  const { start } = useStart();

  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    start(date);
  }, [date]);

  const toUp = () => {
    scrollRef.current.scrollTo(0, 0);
  };

  return (
    <>
      <SmuPreload show={showPreload} />

      <div className="w-100 d-flex smu-bg-body">
        <SideBar open={showSidebar} control={setShowSidebar} />

        <Container
          ref={scrollRef}
          fluid
          className="smu-container scroll-container p-relative px-0"
        >
          <Topbar sidebarControl={setShowSidebar} />

          <Container fluid className="py-3 px-0 px-lg-3">
            <Outlet />
          </Container>

          <button
            type="button"
            className="smu-btn-to-up shadow d-flex d-lg-none"
            onClick={() => toUp()}
          >
            <IconArrowUp color="#fff" />
          </button>
        </Container>
      </div>
    </>
  );
};

export default MainLayout;
