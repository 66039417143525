import React from "react";

const IconPencilEdit = ({ color = "#e31c19" }) => {
  return (
    <svg
      id="UI_icon_edit_light"
      data-name="UI icon/edit/light"
      xmlns="http://www.w3.org/2000/svg"
      width="12.188"
      height="12.188"
      viewBox="0 0 12.188 12.188"
    >
      <path
        id="Union"
        d="M.664,12.188a.667.667,0,0,1-.651-.8L.72,7.854A.66.66,0,0,1,.9,7.514L7.831.585a2,2,0,0,1,2.828,0l.943.943a2,2,0,0,1,0,2.828L4.674,11.285a.665.665,0,0,1-.341.182L.8,12.174A.681.681,0,0,1,.664,12.188ZM6.888,3.414h0l-4.9,4.9-.472,2.357L3.873,10.2l4.9-4.9L6.888,3.414Zm2.357-2.08a.662.662,0,0,0-.472.2l-.943.943L9.716,4.357l.943-.943a.667.667,0,0,0,0-.943l-.943-.943A.661.661,0,0,0,9.245,1.333Z"
        fill={color}
      />
    </svg>
  );
};

export default IconPencilEdit;
