import axios from "axios";
import { SMU_TOKEN } from "../../constants";
import { toast } from "../../helpers/smu-alerts/smu-lerts";

const baseURL =
  "/";
const _this = axios.create({ baseURL });

_this.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(SMU_TOKEN);
    if (token) config.headers.Authorization = "Bearer " + token;
    return config;
  },
  (err) => console.error(err)
);

_this.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 ||
      error.response?.data?.error?.name === "ExpiredJwtToken"
    ) {
      localStorage.removeItem(SMU_TOKEN);
      toast({ icon: "error", title: "La sessión ha expirado!" }).then(() => {
        return (window.location.href = "/");
      });
    }
    return Promise.reject(error);
  }
);

export default _this;
