import interceptors from "./interceptors";

const signIn = (username, password, token) => {
  return interceptors({
    url: "/auth/login",
    method: "POST",
    data: { username, password, token },
  });
};

export { signIn };
