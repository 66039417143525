import React from "react";
import "../../assets/styles/smu-user-icon.scss";
import { IconUser } from "../../icons/Icon";

const SmuUserIcon = ({ color }) => {
  return (
    <div className="smu-user-icon" style={{ backgroundColor: color }}>
      <IconUser color="#fff" />
    </div>
  );
};

export default SmuUserIcon;
