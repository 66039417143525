import React from "react";
import { Modal } from "react-bootstrap";
import BtnClose from "../btn-close/BtnClose";

const ModalGraph = ({ show, onHide, children }) => {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={() => onHide(false)}
      className="smu-modal-graph px-2"
    >
      <BtnClose control={() => onHide(false)} />
      {children}
    </Modal>
  );
};

export default ModalGraph;
