import React from "react";

const IconArrowsUpDown = ({ color = "#e31c19" }) => {
  return (
    <svg
      id="UI_icon_arrow_up_down_light"
      data-name="UI icon/arrow_up_down/light"
      xmlns="http://www.w3.org/2000/svg"
      width="16.667"
      height="13.476"
      viewBox="0 0 16.667 13.476"
    >
      <path
        id="Union"
        d="M1.423,4.411A.833.833,0,1,1,.244,3.232L2.988.488a1.667,1.667,0,0,1,2.357,0L8.089,3.232A.833.833,0,1,1,6.911,4.411L5,2.5V12.571a.833.833,0,0,1-1.667,0V2.5Z"
        fill={color}
      />
      <path
        id="Union-2"
        data-name="Union"
        d="M5,.833a.833.833,0,1,0-1.667,0V10.9L1.423,8.994A.833.833,0,0,0,.244,10.173l2.744,2.744a1.667,1.667,0,0,0,2.357,0l2.744-2.744A.833.833,0,0,0,6.911,8.994L5,10.9Z"
        transform="translate(8.333 0.071)"
        fill={color}
      />
    </svg>
  );
};

export default IconArrowsUpDown;
