import React from "react";

const CellItem = ({ children, _className, style, colSpan }) => {
  return (
    <td colSpan={colSpan || 1}>
      <div className={_className || ""} style={style || {}}>
        {children}
      </div>
    </td>
  );
};

export default CellItem;
