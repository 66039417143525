import React, { useContext } from "react";
import "../../assets/styles/sideBar.scss";
import graphicIcon from "../../assets/images/graphic-icon.png";
import { routes } from "../../router";
import { IconSignOut, IconWarning } from "../../icons/Icon";
import NavItem from "./parts/NavItem";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SMU_INDEX_PATH, SMU_TOKEN, SMU_USER } from "../../constants";
import { StoreContext } from "../../store/StoreContext";

const SideBar = ({ open, control }) => {
  const navigate = useNavigate();
  const { setLoggedUser, setPermissions, permissions, setIsAdmin } = useContext(StoreContext);

  const handleRoutes = routes.filter((r) => {
    return permissions[r.id];
  });

  const goToTableHelp = () => {
    control(false);
    window.open("https://smuenprod.service-now.com/", "_blank");
  };

  const logout = () => {
    Swal.fire({
      title: "¿Deseas cerrar sesión?",
      showCancelButton: true,
      confirmButtonText: "Cerrar sesión",
      confirmButtonColor: "#E31C19",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (!result.isConfirmed) return;

      setLoggedUser(null);
      setPermissions(null);
      setIsAdmin(null);
      localStorage.removeItem(SMU_TOKEN);
      localStorage.removeItem(SMU_USER);
      localStorage.removeItem(SMU_INDEX_PATH);
      navigate("/access", { replace: true });
    });
  };

  return (
    <>
      <div
        className={`sidebar-fade d-lg-none ${open ? "d-block" : "d-none"}`}
        onClick={() => control(false)}
      />

      <div className={`sidebar shadow ${open ? "open" : ""}`}>
        <div className="header d-flex align-items-center">
          <img src={graphicIcon} alt="ICON" />
          <span className="title ms-3 fs-4">Ventas Online</span>
        </div>
        <div className="d-flex flex-column h-100">
          <ul className="menu px-0 py-3">
            {handleRoutes.map((route) => (
              <li
                key={route.id}
                className="py-2"
                onClick={() => control(false)}
              >
                <NavItem route={route} />
              </li>
            ))}
          </ul>

          <ul className="menu px-0 mt-auto">
            <li className="menu-item" onClick={() => logout()}>
              <div className="icon">
                <IconSignOut color="#33383F" />
              </div>
              <span className="text ms-3">Cerrar sesión</span>
            </li>
            <div className="divider my-2 mx-auto w-100" />
            <li className="menu-item" onClick={() => goToTableHelp()}>
              <div className="icon">
                <IconWarning color="#33383F" />
              </div>
              <span className="text ms-3">Mesa de Ayuda</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
