import React from "react";

const SmuButton = ({ control, children, _className }) => {
  return (
    <button
      type="button"
      className={`smu-button-table ${_className || ""}`}
      onClick={control}
    >
      {children}
    </button>
  );
};

export default SmuButton;
