import { useContext } from "react";
import jwtDecode from "jwt-decode";
import { StoreContext } from "../store/StoreContext";
import { SMU_TOKEN, SMU_USER, SMU_INDEX_PATH } from "../constants";

const useAuth = () => {
  const {
    setLoggedUser,
    setPermissions,
    setIsAdmin
  } = useContext(StoreContext);

  const validate = ({ data = {} }) => {
 
    if( Object.keys(data).length > 0 ) {
        localStorage.setItem(SMU_TOKEN, data.token);
        localStorage.setItem(SMU_USER, JSON.stringify(data.user));
        localStorage.setItem(SMU_INDEX_PATH, data.path);
    }
    
    const user = localStorage.getItem(SMU_USER) ?? undefined;
    const token = localStorage.getItem(SMU_TOKEN)?? undefined;
    
    if(token){
    
        const _tokenData = jwtDecode(token);
        const permission = _tokenData?.permission || {};
        setLoggedUser(JSON.parse(user));
        setPermissions(permission);
        setIsAdmin((_tokenData?.admin == true || _tokenData?.admin == "true"));
    }
    
  }

  return { validate };
};

export default useAuth;




