import { useContext } from "react";
import {
  fetchPromotionsPlu,
  fetchPromotionsSale,
  fetchPromotionSummary,
} from "../api/promotions";
import { fetchSalesbyDate } from "../api/sales";
import { StoreContext } from "../store/StoreContext";
import moment from "moment";
import _ from "lodash";

const useStart =  () => {
  const {
    setBusiness,
    setManagers,
    setLocals,
    setPromotionSummary,
    setPromotionsSale,
    setPromotionsPlu,
    setLoading,
    loading,
    permissions,
    setViewTitle
  } = useContext(StoreContext);

  const start = async (_date) => {
    if (loading || !_date) return;

    setLoading(true);
    const __date = moment(_date).format("YYYY-MM-DD");


    fetchPromotionSummary()

    const getPortalData = async () => {

      const [sales, promotionsSummary, promotionsSales, promotionsPlu] = await Promise.all([
        permissions?.venta_online == true ? fetchSalesbyDate(__date).catch(e => e) : Promise.resolve(),
        permissions?.monitor_promocional == true ? fetchPromotionSummary(__date).catch(e => e) : Promise.resolve(),
        permissions?.monitor_promocional == true ? fetchPromotionsSale(__date).catch(e => e) : Promise.resolve(),
        permissions?.monitor_promocional == true ? fetchPromotionsPlu(__date).catch(e => e) : Promise.resolve(),
      ]);

      return {sales, promotionsSummary, promotionsSales, promotionsPlu}

    }

    const {sales, promotionsSummary, promotionsSales, promotionsPlu} = await getPortalData();
    setLoading(false);
  
    //Sales
    setBusiness(sales?.data?.o_tot_negocio || []);
    setManagers(sales?.data?.o_tot_zonal || []);
    setLocals(sales?.data?.o_tot_local || []);

    setViewTitle(sales?.data?.o_tot_negocio[0]?.FECHA_ACT);

    //Prmotions Summary
    let summary = promotionsSummary?.data[0] || {};
    summary = {
      ...summary,
      poncentParti: _.round(summary.poncentParti, 2),
      poncentPartiAcum: _.round(summary.poncentPartiAcum, 2),
      stockDiarioDisponible: _.round(summary.stockDiarioDisponible, 2),
      unidadesVendidasApoteosicoAcum: _.round(
        summary.unidadesVendidasApoteosicoAcum,
        2
      ),
      unidadesVendidasApoteosicoDiario: _.round(
        summary.unidadesVendidasApoteosicoDiario,
        2
      ),
      ventaTotalAcum: _.round(summary.ventaTotalAcum, 2),
      ventaTotalApoteosicoAcum: _.round(
        summary.ventaTotalApoteosicoAcum,
        2
      ),
      ventaTotalDiario: _.round(summary.ventaTotalDiario, 2),
      ventaTotalDiarioApoteosico: _.round(
        summary.ventaTotalDiarioApoteosico,
        2
      ),
    };
    setPromotionSummary(summary);

    //Promotions Sales
    const _promotionsSale = _.map(promotionsSales?.data, (ps) => ({
      ...ps,
      poncentParti: _.round(ps.poncentParti, 2),
      poncentPartiAcum: _.round(ps.poncentPartiAcum, 2),
      ventaTotalAcum: _.round(ps.ventaTotalAcum, 2),
      ventaTotalComerciante: _.round(ps.ventaTotalComerciante, 2),
      ventaTotalDiario: _.round(ps.ventaTotalDiario, 2),
    }));
    setPromotionsSale(_promotionsSale);

    //Promotions Plu
    const _promotionsPlu = _.map(promotionsPlu?.data, (pp) => ({
      ...pp,
      ventaTotalAcum: _.round(pp.ventaTotalAcum, 2),
      ventaTotalComerciante: _.round(pp.ventaTotalComerciante, 2),
      ventaTotalDiario: _.round(pp.ventaTotalDiario, 2),
    }));
    setPromotionsPlu(_promotionsPlu);
  
  }

  return { start };
};

export default useStart;
