import interceptors from "./interceptors/index";

const fetchSalesbyDate = (date) => {
  return interceptors({
    url: "/online-ventapreliminar/sales",
    method: "GET",
    params: { date },
  });
};

export { fetchSalesbyDate };
